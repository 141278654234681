import { useCallback, useEffect, useMemo, useState } from 'react';
import { ExoDeviceInfoFeature } from '@egzotech/exo-session';
import { useSignalEffect } from '@preact/signals-react/runtime';
import { ModalType } from 'containers/modals/Modal';
import { DeviceManager } from 'libs/exo-session-manager/core';
import { useDevice } from 'libs/exo-session-manager/react';
import { EGZOTechHostApi } from 'services/EGZOTechHostApi';
import { exoClinicApi } from 'services/ExoClinicBackendApi';
import { setVersion } from 'slices/firmwareSlice';
import { closeModal, openModal } from 'slices/modalSlice';

import { useAppDispatch, useAppSelector } from './store';

export const useFirmwareUpdateNotification = () => {
  const [refreshUpdateNotificationCheck, setRefreshUpdateNotificationCheck] = useState(0);
  const showFirmwareUpdateInfo = EGZOTechHostApi.instance?.options?.showFirmwareUpdateInfo;
  const zebraPresent = EGZOTechHostApi.instance?.options?.zebraPresent;
  const backendFWVersion = useAppSelector(state => state.firmware.version);
  const { session, candidates } = useDevice();
  const dispatch = useAppDispatch();

  const [deviceFWVersion, setDeviceFWVersion] = useState<number | null>(null);

  const fetchBackendFirmwareVersion = useCallback(async () => {
    const data = await exoClinicApi.info.biomea();
    if (data) {
      dispatch(setVersion(data.fw_version));
    }
  }, [dispatch]);

  useEffect(() => {
    if (zebraPresent && !backendFWVersion) {
      fetchBackendFirmwareVersion();
    }
  }, [fetchBackendFirmwareVersion, backendFWVersion, zebraPresent]);

  const deviceInfoFeature = useMemo(() => {
    if (!session) {
      return null;
    }

    // At the moment of development ExoDeviceInfoFeature can be used only with stella bio
    if (!zebraPresent) {
      return null;
    }

    return session.activate(ExoDeviceInfoFeature);
  }, [session, zebraPresent]);

  const onDeviceFirmwareVersionChange = useCallback(() => {
    if (deviceInfoFeature?.osVersion) {
      setDeviceFWVersion(deviceInfoFeature.osVersion);
    }
  }, [deviceInfoFeature?.osVersion]);

  useSignalEffect(() => {
    setRefreshUpdateNotificationCheck(DeviceManager.refreshUpdateNotificationCheck.value);
  });

  useEffect(() => {
    if (deviceInfoFeature) {
      deviceInfoFeature.onChange = onDeviceFirmwareVersionChange;
    }
  }, [deviceInfoFeature, onDeviceFirmwareVersionChange]);

  useEffect(() => {
    if (!zebraPresent) {
      return;
    }

    // Try to find os version using selected session candidate
    const resource = session?.candidate.resource;

    if (resource && 'device' in resource) {
      const device = resource.device;

      if (
        device &&
        'os' in device &&
        typeof device.os === 'object' &&
        device.os &&
        'version' in device.os &&
        (typeof device.os.version === 'string' || typeof device.os.version === 'number')
      ) {
        setDeviceFWVersion(+device.os.version);
      }
    }
  }, [session?.candidate.resource, zebraPresent, refreshUpdateNotificationCheck]);

  useEffect(() => {
    if (!zebraPresent) {
      return;
    }

    // Try to find os version using any configured candidate (configured candidates are always
    // connected to the tablet through WiFi Direct, unconfigured candidates comes from bluetooth)
    const configuredCandidates = candidates.filter(
      v => !v.resource || !('unconfigured' in v.resource) || !v.resource.unconfigured,
    );

    if (configuredCandidates.length > 1) {
      // There are more than one candidate configured. In this situation we do not really know
      // which one should we be checking, but in reality this situation should not happend, because
      // currently we allow only one StellaBIO device to connect to the tablet.
      return;
    }

    const resource = configuredCandidates.at(0)?.resource;

    if (resource && 'device' in resource) {
      const device = resource.device;

      if (
        device &&
        'os' in device &&
        typeof device.os === 'object' &&
        device.os &&
        'version' in device.os &&
        (typeof device.os.version === 'string' || typeof device.os.version === 'number')
      ) {
        setDeviceFWVersion(+device.os.version);
      }
    }
  }, [candidates, zebraPresent, refreshUpdateNotificationCheck]);

  useEffect(() => {
    if (!showFirmwareUpdateInfo || !backendFWVersion || !deviceFWVersion) {
      return;
    }

    if (backendFWVersion > deviceFWVersion) {
      dispatch(openModal({ type: ModalType.UPDATE_FIRMWARE_NOTIFICATION }));
      return () => {
        dispatch(closeModal(ModalType.UPDATE_FIRMWARE_NOTIFICATION));
      };
    }
  }, [dispatch, backendFWVersion, deviceFWVersion, showFirmwareUpdateInfo]);
};
