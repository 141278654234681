import { Logger } from '@egzotech/universal-logger-js';
import { convertVersionToString } from 'hooks/useFirmwareVersion';
import { DeviceManager } from 'libs/exo-session-manager/core';
import {
  meissaOTModulesVersionConfiguration,
  sidraLegModulesVersionConfiguration,
} from 'libs/exo-session-manager/core/global/modules-configurations';
import { URLSearchParams } from 'url';

import { EGZOTechHostApi } from './EGZOTechHostApi';
import { RESTMethod, RESTResponse } from './RESTClient';

const REQUEST_TIMEOUT = 100;
class MockedApi {
  private static logger = Logger.getInstance('MockedApi');
  private updateStarted = false;
  private updateProgress = 0;

  private mock: Record<string, () => unknown> = {
    'GET:/device/update/current': () => {
      return {
        version: '0.11.11',
      };
    },
    'GET:/device/update': () => {
      return {
        version: '0.11.11',
        available: {
          version: '1.0.0',
          source: 'remote',
        },
      };
    },
    'GET:/device/update/available': () => {
      return {
        version: '1.0.0',
        source: 'remote',
      };
    },
    'POST:/device/update': () => {
      this.updateStarted = true;
      this.updateProgress = 0;
      return {};
    },
    'POST:/device/update/restart': () => {
      window.location.reload();
      return {};
    },
    'GET:/device/update/status': () => {
      if (this.updateStarted && this.updateProgress < 100) {
        this.updateProgress += 5;
      }
      if (this.updateProgress > 5) {
        // simulate error during the update
        //return undefined;
      }
      return {
        progress: this.updateProgress,
        running: this.updateStarted,
        completed: this.updateProgress === 100,
      };
    },
    'POST:/device/update/firmware': () => {
      this.updateStarted = true;
      this.updateProgress = 0;
      return {};
    },
    'POST:/device/update/firmware/restart': () => {
      window.location.reload();
      return {};
    },
    'GET:/device/update/firmware/status': () => {
      if (this.updateStarted && this.updateProgress < 100) {
        this.updateProgress += 5;
      }
      if (this.updateProgress > 5) {
        // simulate error during the update
        //return undefined;
      }
      return {
        progress: this.updateProgress,
        running: this.updateStarted,
        completed: this.updateProgress === 100,
      };
    },
    'GET:/device/wifi/status': () => {
      return {
        connected: false,
      };
    },
    'GET:/db/import': () => {
      return {};
    },
    'dummy:GET:/device/update/firmware/valid-version': () => {
      const deviceManager = (window as any).deviceManager as DeviceManager;
      if (!deviceManager.selectedDevice || !deviceManager.selectedDevice.type) {
        throw new Error('Cannot mock expected firmware without a device type');
      }
      return deviceManager.selectedDevice.type === 'sidra-leg'
        ? Object.fromEntries(
            Object.entries(sidraLegModulesVersionConfiguration.modules)
              .filter(([, v]) => v)
              .map(([k, v]) => [k, convertVersionToString(v!.version)]),
          )
        : deviceManager.selectedDevice.type === 'meissa-ot'
        ? Object.fromEntries(
            Object.entries(meissaOTModulesVersionConfiguration.modules)
              .filter(([, v]) => v)
              .map(([k, v]) => [k, convertVersionToString(v!.version)]),
          )
        : null;
    },
  };

  constructor() {
    if (EGZOTechHostApi.instance?.options?.mockedApi) {
      MockedApi.logger.warn('constructor', 'Mocked API is enabled');
    }
  }

  isMocked(method: RESTMethod, path: string, params?: URLSearchParams) {
    if (!EGZOTechHostApi.instance?.options?.mockedApi) {
      return false;
    }

    const deviceManager = (window as any).deviceManager as DeviceManager;

    if (
      (deviceManager.getDummyController() || params?.get('deviceId')?.startsWith('dummy')) &&
      typeof this.mock[`dummy:${method}:${path}`] === 'function'
    ) {
      MockedApi.logger.info('isMocked', `Using device dummy mocked API for ${method}:${path}`);
      return true;
    }

    const result = typeof this.mock[`${method}:${path}`] === 'function';

    if (result) {
      MockedApi.logger.info('isMocked', `Using mocked API for ${method}:${path}`);
    }

    return result;
  }

  mockedFetch(method: RESTMethod, path: string): Promise<RESTResponse<any>> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const deviceManager = (window as any).deviceManager as DeviceManager;
        let mock = this.mock[`${method}:${path}`];

        if (deviceManager.getDummyController() && typeof this.mock[`dummy:${method}:${path}`] === 'function') {
          mock = this.mock[`dummy:${method}:${path}`];
        }

        if (mock) {
          try {
            resolve(new Response(JSON.stringify(mock())));
          } catch (ex) {
            resolve(new Response(ex instanceof Error ? ex.stack?.toString() : '', { status: 500 }));
          }
        } else {
          reject();
        }
      }, REQUEST_TIMEOUT);
    });
  }
}

export const mockedApi = new MockedApi();
