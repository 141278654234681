import { CAMActiveMovementDirection } from '@egzotech/exo-session/features/cam';
import { MovementType } from '@egzotech/exo-session/features/motor';
import { getMotorNameByProgram } from 'helpers/getMotorNameByProgram';
import { Signal, signal } from 'helpers/signal';
import {
  basingParameterTemplateBiDirectionalEMS,
  basingParameterTemplateUniDirectionalEMS,
  DurationBasedOn,
  exerciseParameterTemplateBiDirectionalEMS,
  exerciseParameterTemplateUniDirectionalEMS,
  SettingsParameterId,
  SettingsParametersPlace,
  SettingsTreeElement,
  TriggeringType,
} from 'libs/exo-session-manager/core/settings/SettingsTemplates';
import { EMS_INSTANCES } from 'types';

import { exerciseActionTracker } from '../exerciseActionTracker';
import { SensorsName, triggeringMethod, Unpacked } from '../types';
import {
  CAMParameters,
  GeneratedCAMProgramDefinitionPrimary,
  isGeneratedCAMProgramDefinitionPrimary,
} from '../types/GeneratedCAMProgramDefinition';
import {
  CPMParameters,
  GeneratedCPMProgramDefinitionPrimary,
  isGeneratedCPMProgramDefinitionPrimary,
} from '../types/GeneratedCPMProgramDefinition';
import { EMSParameters } from '../types/GeneratedElectrostimProgramDefinition';
import {
  BlockerValue,
  CarColors,
  ExerciseDefinition,
  GameBackgrounds,
  GameComprehensiveParameters,
  GamePhasicParameters,
  GameSteeringModes,
  GeneratedExerciseDefinition,
  isCAMExerciseDefinition,
  isGameExerciseDefinition,
  isSpecificExerciseDefinition,
  isSpecificGeneratedExerciseDefinition,
} from '../types/GeneratedExerciseDefinition';
import { ProgramParameterDefinition } from '../types/GeneratedProgramDefinition';

import CAMSettings from './CAMSettings';
import CPMSettings from './CPMSettings';
import EMSSettings from './EMSSettings';
import GameSettings from './GameSettings';

export type CPMPhasicParameters = NonNullable<Required<CPMParameters>['phases'][number]>;
export type CAMPhasicParameters = NonNullable<Required<CAMParameters>['phases'][number]>;
export type EMSPhasicParameters = NonNullable<Required<EMSParameters>['phases'][number]>;
export type RequiredGamePhasicParameters = NonNullable<Required<GamePhasicParameters>>;

export type CPMPhasicParameterId = keyof CPMPhasicParameters;
export type CAMPhasicParameterId = keyof CAMPhasicParameters;
export type EMSPhasicParameterId = keyof EMSPhasicParameters;
export type GamePhasicParameterId = keyof GamePhasicParameters;

export type CPMComprehensiveParameters = Omit<CPMParameters, 'phases'>;
export type CAMComprehensiveParameters = Omit<CPMParameters, 'phases'>;
export type EMSComprehensiveParameters = Omit<EMSParameters, 'phases'>;
export type CPMComprehensiveParameterId = keyof Omit<CPMParameters, 'phases'>;
export type CAMComprehensiveParameterId = keyof Omit<CAMParameters, 'phases'>;
//TODO: currently does not support maxSupportedChannels parameter
export type EMSComprehensiveParameterId = keyof Omit<EMSParameters, 'phases' | 'maxSupportedChannels'>;
export type GameComprehensiveParameterId = keyof GameComprehensiveParameters;

export type CPMParameterId = CPMPhasicParameterId | CPMComprehensiveParameterId;
export type CAMParameterId = CAMPhasicParameterId | CAMComprehensiveParameterId;
export type EMSParameterId = EMSPhasicParameterId | EMSComprehensiveParameterId;
export type GameParameterId = GamePhasicParameterId | GameComprehensiveParameterId;

export type GeneralParameterId = CPMParameterId | CAMParameterId | EMSParameterId | GameParameterId;

type ExtractProgramParameterDefinitionValueType<T> = T extends ProgramParameterDefinition<any, infer U> ? U : never;

export type SettingsParameters<T extends { [key: string]: ProgramParameterDefinition<any, any> }> = {
  [K in keyof T]-?: {
    currentValue?: ExtractProgramParameterDefinitionValueType<T[K]>;
    previousValue?: ExtractProgramParameterDefinitionValueType<T[K]>;
    values: readonly ExtractProgramParameterDefinitionValueType<T[K]>[];
    paramAvailability?: { value: number | string; disabled: boolean }[];
    default: ExtractProgramParameterDefinitionValueType<T[K]>;
    blockAfterStart: boolean;
    availableIf?: `${SettingsParameterId}=${string}`;
  };
};

export type CPMSettingsParameters = {
  phases: SettingsParameters<CPMPhasicParameters>[];
} & SettingsParameters<Omit<CPMParameters, 'phases'>>;

export type CAMSettingsParameters = {
  phases: SettingsParameters<CAMPhasicParameters>[];
} & SettingsParameters<Omit<CAMParameters, 'phases'>>;

export type EMSSettingsParameters = {
  phases: SettingsParameters<EMSPhasicParameters>[];
} & SettingsParameters<Omit<EMSParameters, 'phases' | 'maxSupportedChannels'>>;

export type GameSettingsParameters = {
  phases: SettingsParameters<GamePhasicParameters>[];
};

export type Parameter =
  | {
      type: 'cpm';
      id: CPMPhasicParameterId | CPMComprehensiveParameterId;
      phaseIndex?: number;
      repetition?: number;
    }
  | {
      type: 'cam';
      id: CAMPhasicParameterId | CAMComprehensiveParameterId;
      phaseIndex?: number;
    }
  | {
      type: 'ems';
      id: EMSPhasicParameterId | EMSComprehensiveParameterId;
      phaseIndex?: number;
      repetition?: number;
    }
  | {
      type: 'game';
      id: GamePhasicParameterId | GameComprehensiveParameterId;
      phaseIndex?: number;
    };

export type PhaseParameterValueType<T extends CPMPhasicParameterId | EMSPhasicParameterId> =
  T extends CPMPhasicParameterId
    ? Unpacked<CPMSettingsParameters['phases']>[T]['currentValue']
    : T extends EMSPhasicParameterId
    ? Unpacked<EMSSettingsParameters['phases']>[T]['currentValue']
    : never;

export interface SettingsActions {
  /**
   * Allow to change given parameter for the program
   */
  setParameter: (
    paramId: SettingsParameterId,
    value: number | string,
    trackerOptions?: { ignore?: boolean; force?: boolean },
  ) => void;
}

export interface SettingsRequiredMethods {
  updateDefinition: (definition: ExerciseDefinition) => void;
}

const trainingCategories = ['cpm', 'cam', 'ems', 'game'] as const;
export type TrainingCategories = (typeof trainingCategories)[number];

export type Settings<T extends TrainingCategories = TrainingCategories> = {
  [K in T]?: K extends 'cpm'
    ? CPMSettings
    : K extends 'ems'
    ? EMSSettings
    : K extends 'cam'
    ? CAMSettings
    : K extends 'game'
    ? GameSettings
    : SettingsRequiredMethods;
};

export const emsPhasicParameters = [
  'pulseFrequency',
  'pulseWidth',
  'runTime',
  'riseTime',
  'fallTime',
  'pauseTime',
  'plateauTime',
  'pulseShape',
];

const gamePhasicParameters = [
  'speed',
  'routeWidth',
  'oponent',
  'blockers',
  'gameBackground',
  'carColor',
  'gameSteeringMode',
  'lives',
];

const cpmComprehensiveParameters = ['maxTime', 'maxBackwardForceLimit', 'triggeringType', 'durationBasedOn'];
export const cpmPhasicParameters = [
  'time',
  'repetitions',
  'speed',
  'pauseTimeInROMMin',
  'pauseTimeInROMMax',
  'movementType',
  'forceTriggerSource',
  'triggeringMethod',
];

const camComprehensiveParameters = ['maxTime', 'maxTorque', 'maxBackwardForceLimit', 'durationBasedOn'];
export const camPhasicParameters = [
  'time',
  'repetitions',
  'maxSpeed',
  'pauseTimeInROMMin',
  'pauseTimeInROMMax',
  'endActiveMovementForce',
  'startActiveMovementForce',
  'activeMovementDirection',
  'forceSource',
  'deadband',
];

/**
 * An auxiliary function that determines whether the string is a ems phase parameter id
 * @param str Checked string
 * @returns true if string is valid EMSPhaseParameterId
 */
export function isEMSPhasicParameterId(str: string): str is EMSPhasicParameterId {
  return emsPhasicParameters.includes(str);
}

/**
 * An auxiliary function that determines whether the string is a game phase parameter id
 * @param str Checked string
 * @returns true if string is valid key of GameParameters
 */
export function isGamePhasicParameterId(str: string): str is GamePhasicParameterId {
  return gamePhasicParameters.includes(str);
}

/**
 * An auxiliary function that determines whether the string is a cpm phase parameter id
 * @param str Checked string
 * @returns true if string is valid CPMPhaseParameterId
 */
export function isCPMPhasicParameterId(str: string): str is CPMPhasicParameterId {
  return cpmPhasicParameters.includes(str);
}

/**
 * An auxiliary function that determines whether the string is a cam phase parameter id
 * @param str Checked string
 * @returns true if string is valid CAMPhasicParameterId
 */
export function isCAMPhasicParameterId(str: string): str is CAMPhasicParameterId {
  return camPhasicParameters.includes(str);
}

/**
 * An auxiliary function that determines whether the string is a cpm comprehensive parameter id
 * @param str Checked string
 * @returns true if string is valid CPMComprehensiveParameterId
 */
export function isCPMComprehensiveParameterId(str: string): str is CPMComprehensiveParameterId {
  return cpmComprehensiveParameters.includes(str);
}

/**
 * An auxiliary function that determines whether the string is a cam comprehensive parameter id
 * @param str Checked string
 * @returns true if string is valid CAMComprehensiveParameterId
 */
export function isCAMComprehensiveParameterId(str: string): str is CAMComprehensiveParameterId {
  return camComprehensiveParameters.includes(str);
}

function assertNumber(value: unknown, id?: string): asserts value is number {
  if (typeof value !== 'number') {
    throw new Error(`Value ${id ? `'${id}' ` : ''}must be number`);
  }
}

function assertString(value: unknown, id?: string): asserts value is string {
  if (typeof value !== 'string') {
    throw new Error(`Value ${id ? `'${id}' ` : ''}must be string`);
  }
}

export type ParameterHistoryId = 'initial' | 'change';

type CPMPhasicParameterDefinition = {
  type: 'cpm';
  id: CPMPhasicParameterId;
  parameter: Signal<SettingsParameters<CPMPhasicParameters>[CPMPhasicParameterId]>;
  phaseIndex: number;
};

type CPMComprehensiveParameterDefinition = {
  type: 'cpm';
  id: CPMComprehensiveParameterId;
  parameter: Signal<SettingsParameters<CPMComprehensiveParameters>[CPMComprehensiveParameterId]>;
};

type CAMPhasicParameterDefinition = {
  type: 'cam';
  id: CAMPhasicParameterId;
  parameter: Signal<SettingsParameters<CAMPhasicParameters>[CAMPhasicParameterId]>;
  phaseIndex: number;
};

type CAMComprehensiveParameterDefinition = {
  type: 'cam';
  id: CAMComprehensiveParameterId;
  parameter: Signal<SettingsParameters<CAMComprehensiveParameters>[CAMComprehensiveParameterId]>;
};

type EMSPhasicParameterDefinition = {
  type: 'ems';
  id: EMSPhasicParameterId;
  parameter: Signal<SettingsParameters<EMSPhasicParameters>[EMSPhasicParameterId]>;
  phaseIndex: number;
};

type EMSComprehensiveParameterDefinition = {
  type: 'ems';
  id: EMSComprehensiveParameterId;
  parameter: Signal<SettingsParameters<EMSComprehensiveParameters>[EMSComprehensiveParameterId]>;
};

type GamePhasicParameterDefinition = {
  type: 'game';
  id: GamePhasicParameterId;
  parameter: Signal<SettingsParameters<GamePhasicParameters>[GamePhasicParameterId]>;
  phaseIndex: number;
};

type GameComprehensiveParameterDefinition = {
  type: 'game';
  id: GameComprehensiveParameterId;
  parameter: Signal<SettingsParameters<GameComprehensiveParameters>[GameComprehensiveParameterId]>;
};

export type ParameterDefinition =
  | CPMPhasicParameterDefinition
  | CPMComprehensiveParameterDefinition
  | CAMPhasicParameterDefinition
  | CAMComprehensiveParameterDefinition
  | EMSPhasicParameterDefinition
  | EMSComprehensiveParameterDefinition
  | GamePhasicParameterDefinition
  | GameComprehensiveParameterDefinition;

export type SettingStatus = 'not-commited' | 'pending' | 'commited';

export class SettingsBuilder implements SettingsRequiredMethods, SettingsActions {
  private _settingStatus: Signal<SettingStatus> = signal('not-commited', 'SettingsBuilder._settingStatus');
  private _currentDefinition: ExerciseDefinition;
  private _parametersMap: Signal<Partial<Record<SettingsParameterId, ParameterDefinition>>> = signal(
    {},
    'SettingsBuilder._parametersMap',
  );
  private _parametersTree: Record<SettingsParametersPlace, Signal<SettingsTreeElement[]>> = {
    basing: signal([], 'SettingsBuilder._parametersTree.basing'),
    exercise: signal([], 'SettingsBuilder._parametersTree.exercie'),
  };

  readonly data: Settings = {};

  get hasData() {
    return Object.keys(this.data).length > 0;
  }

  get hasDefinitionParameters() {
    return Object.values(this.data).some(v => v.hasDefinitionParameters);
  }

  constructor(
    private readonly originalDefinition: GeneratedExerciseDefinition,
    initialDefinition: GeneratedExerciseDefinition = originalDefinition,
  ) {
    let primaryMotorKey = null;

    if (
      isSpecificGeneratedExerciseDefinition(originalDefinition, [
        'cpm',
        'cpm-emg',
        'cpm-ems',
        'cpm-ems-emg',
        'cpm-force',
      ])
    ) {
      primaryMotorKey = getMotorNameByProgram(originalDefinition, 'primary');

      if (!primaryMotorKey || !originalDefinition.cpm[primaryMotorKey]) {
        throw new Error(`Missing CPM program definition for primary motor: ${primaryMotorKey}`);
      }

      this.data.cpm = new CPMSettings(
        this,
        originalDefinition,
        primaryMotorKey,
        isSpecificGeneratedExerciseDefinition(initialDefinition, [
          'cpm',
          'cpm-emg',
          'cpm-ems',
          'cpm-ems-emg',
          'cpm-force',
        ])
          ? initialDefinition
          : undefined,
      );
    }

    if (isCAMExerciseDefinition(originalDefinition)) {
      primaryMotorKey = getMotorNameByProgram(originalDefinition, 'primary');

      if (!primaryMotorKey || !originalDefinition.cam[primaryMotorKey]) {
        throw new Error(`Missing CAM program definition for primary motor: ${primaryMotorKey}`);
      }

      this.data.cam = new CAMSettings(
        originalDefinition,
        primaryMotorKey,
        isCAMExerciseDefinition(initialDefinition) ? initialDefinition : undefined,
      );
    }

    if (isSpecificGeneratedExerciseDefinition(originalDefinition, ['cpm-ems', 'cpm-ems-emg'])) {
      this.data.ems = new EMSSettings(
        originalDefinition,
        EMS_INSTANCES,
        isSpecificGeneratedExerciseDefinition(initialDefinition, ['cpm-ems', 'cpm-ems-emg'])
          ? initialDefinition
          : undefined,
      );
    }
    if (isSpecificGeneratedExerciseDefinition(originalDefinition, ['ems', 'ems-emg'])) {
      this.data.ems = new EMSSettings(
        originalDefinition,
        undefined,
        isSpecificGeneratedExerciseDefinition(initialDefinition, ['ems', 'ems-emg']) ? initialDefinition : undefined,
      );
    }
    if (isGameExerciseDefinition(originalDefinition)) {
      this.data.game = new GameSettings(
        originalDefinition,
        isGameExerciseDefinition(initialDefinition) ? initialDefinition : undefined,
      );
    }

    this._currentDefinition = structuredClone(initialDefinition) as ExerciseDefinition;
    exerciseActionTracker.add('parameter-adjustment', 'initial-program-settings', { definition: initialDefinition });
    this.initializeParameters();
  }

  private getSettingsTemplate(args: { templatePlace: SettingsParametersPlace }) {
    const triggeringType = this.data.cpm?.triggeringType ?? 'uni-directional';
    const withRangeParameter = this.data.cam !== undefined || this.data.cpm !== undefined;

    switch (args.templatePlace) {
      case 'basing':
        switch (triggeringType) {
          case 'uni-directional':
            return basingParameterTemplateUniDirectionalEMS;
          case 'bi-directional':
            return basingParameterTemplateBiDirectionalEMS;
        }
        break;
      case 'exercise':
        switch (triggeringType) {
          case 'uni-directional':
            return exerciseParameterTemplateUniDirectionalEMS(withRangeParameter);
          case 'bi-directional':
            return exerciseParameterTemplateBiDirectionalEMS(withRangeParameter);
        }
    }
  }

  private filterParameterTemplate(
    parameterTemplate: SettingsTreeElement[],
    parameters: Partial<Record<SettingsParameterId, any>>,
  ) {
    const result: SettingsTreeElement[] = [];

    for (const parameter of parameterTemplate) {
      switch (parameter.type) {
        case 'parameter':
          if (parameters[parameter.parameterId]) {
            result.push(parameter);
          }

          break;
        case 'custom-parameter':
          if (parameter.parameterId === 'ems-current-phase-0' || parameter.parameterId === 'ems-current-phase-1') {
            if (this.data['ems']) {
              result.push(parameter);
            }
          } else {
            result.push(parameter);
          }
          break;

        case 'category':
          {
            const children = this.filterParameterTemplate(parameter.children, parameters);
            if (children.length) {
              result.push({ ...parameter, children });
            }
          }
          break;
      }
    }
    return result;
  }

  private parseConditionValue(value: `${SettingsParameterId}=${string}`) {
    return value.split('=') as [SettingsParameterId, string];
  }

  private filterDependencies() {
    for (const key of Object.keys(this._parametersMap.peek()) as SettingsParameterId[]) {
      const param = this._parametersMap.peek()[key] as ParameterDefinition;

      const availableIf = param.parameter.peek().availableIf;
      if (availableIf) {
        const [k, v] = this.parseConditionValue(availableIf);
        if (this._parametersMap.peek()[k]?.parameter.peek().currentValue !== v) {
          delete this._parametersMap.peek()[key];
        }
      }
    }
  }

  private initializeParameters() {
    const parameters: Partial<Record<SettingsParameterId, ParameterDefinition>> = {};

    Object.entries(this.data).forEach(([settingType, settingInstance]) => {
      Object.entries(settingInstance.parameters).forEach(([key, val]) => {
        if (key === 'phases') {
          Object.entries(settingInstance.parameters.phases).forEach(([phaseKey, phaseValue]) => {
            Object.entries(phaseValue).forEach(([paramKey, paramValue]) => {
              switch (settingType) {
                case 'cpm':
                  {
                    const parameter = paramValue as Signal<
                      SettingsParameters<CPMPhasicParameters>[CPMPhasicParameterId]
                    >;
                    //FIXME - parameters probably shoudn't return not valid parameters for current program
                    if (!parameter.value.values.length) {
                      return;
                    }
                    parameters[`${settingType}.${phaseKey}.${paramKey}` as SettingsParameterId] = {
                      type: 'cpm',
                      id: paramKey as CPMPhasicParameterId,
                      phaseIndex: Number(phaseKey),
                      parameter,
                    };
                  }
                  break;
                case 'cam':
                  {
                    const parameter = paramValue as Signal<
                      SettingsParameters<CAMPhasicParameters>[CAMPhasicParameterId]
                    >;
                    if (!parameter.value.values.length) {
                      return;
                    }

                    parameters[`${settingType}.${phaseKey}.${paramKey}` as SettingsParameterId] = {
                      type: 'cam',
                      id: paramKey as CAMPhasicParameterId,
                      phaseIndex: Number(phaseKey),
                      parameter,
                    };
                  }
                  break;

                case 'ems':
                  {
                    const parameter = paramValue as Signal<
                      SettingsParameters<EMSPhasicParameters>[EMSPhasicParameterId]
                    >;
                    if (!parameter.value.values.length) {
                      return;
                    }
                    {
                      parameters[`${settingType}.${phaseKey}.${paramKey}` as SettingsParameterId] = {
                        type: 'ems',
                        id: paramKey as EMSPhasicParameterId,
                        phaseIndex: Number(phaseKey),
                        parameter,
                      };
                    }
                  }
                  break;
                case 'game':
                  {
                    const parameter = paramValue as Signal<
                      SettingsParameters<GamePhasicParameters>[GamePhasicParameterId]
                    >;
                    if (!parameter.value.values.length) {
                      return;
                    }
                    parameters[`${settingType}.${phaseKey}.${paramKey}` as SettingsParameterId] = {
                      type: 'game',
                      id: paramKey as GamePhasicParameterId,
                      phaseIndex: Number(phaseKey),
                      parameter,
                    };
                  }
                  break;
              }
            });
          });
        } else {
          switch (settingType) {
            case 'cpm':
              {
                const parameter = val as Signal<
                  SettingsParameters<CPMComprehensiveParameters>[CPMComprehensiveParameterId]
                >;
                if (!parameter.value.values.length) {
                  return;
                }
                parameters[`${settingType}.${key}` as SettingsParameterId] = {
                  type: 'cpm',
                  id: key as CPMComprehensiveParameterId,
                  parameter,
                };
              }
              break;

            case 'cam':
              {
                const parameter = val as Signal<
                  SettingsParameters<CAMComprehensiveParameters>[CAMComprehensiveParameterId]
                >;
                if (!parameter.value.values.length) {
                  return;
                }
                parameters[`${settingType}.${key}` as SettingsParameterId] = {
                  type: 'cam',
                  id: key as CAMComprehensiveParameterId,
                  parameter,
                };
              }
              break;
            case 'ems':
              {
                const parameter = val as Signal<
                  SettingsParameters<EMSComprehensiveParameters>[EMSComprehensiveParameterId]
                >;
                if (!parameter.value.values.length) {
                  return;
                }
                parameters[`${settingType}.${key}` as SettingsParameterId] = {
                  type: 'ems',
                  id: key as EMSComprehensiveParameterId,
                  parameter,
                };
              }
              break;
            case 'game':
              {
                const parameter = val as Signal<
                  SettingsParameters<GameComprehensiveParameters>[GameComprehensiveParameterId]
                >;
                if (!parameter.value.values.length) {
                  return;
                }
                parameters[`${settingType}.${key}` as SettingsParameterId] = {
                  type: 'game',
                  id: key as GameComprehensiveParameterId,
                  parameter,
                };
              }
              break;
          }
        }
      });
    });

    this._parametersMap.value = parameters;
    this.filterDependencies();

    this._parametersTree['basing'].value = this.filterParameterTemplate(
      this.getSettingsTemplate({ templatePlace: 'basing' }),
      parameters,
    );
    this._parametersTree['exercise'].value = this.filterParameterTemplate(
      this.getSettingsTemplate({ templatePlace: 'exercise' }),
      parameters,
    );
  }

  get basingParameters() {
    return {
      parameters: this._parametersMap,
      parametersTree: this._parametersTree['basing'],
    };
  }

  get exerciseParameters() {
    return {
      parameters: this._parametersMap,
      parametersTree: this._parametersTree['exercise'],
    };
  }

  get settingStatus() {
    return this._settingStatus;
  }

  get definition() {
    return this._currentDefinition;
  }

  // TODO: passing definition perhaps can be omitted when we decide how we store definition in exercise class
  updateDefinition(definition = this._currentDefinition, ignoreTracker = false) {
    Object.values(this.data).forEach(setting => {
      if (setting) {
        setting.updateDefinition(definition);
      }
    });
    this._currentDefinition = structuredClone(definition);

    if (!ignoreTracker) {
      exerciseActionTracker.add('parameter-adjustment', 'program-settings-update', {
        definition: structuredClone(definition),
      });
    }
  }

  setParameter(
    paramId: SettingsParameterId,
    value: number | string,
    trackerOptions: { ignore?: boolean; force?: boolean } = { ignore: false, force: false },
  ) {
    this._settingStatus.value = 'pending';
    const parameter = this._parametersMap.peek()[paramId];
    if (!parameter) {
      return;
    }

    if (parameter.type === 'cpm') {
      if (!this.data?.cpm) {
        throw new Error('Cannot set parameter for CPM Program. No CPM definition.');
      }

      if (
        !isSpecificGeneratedExerciseDefinition(this.originalDefinition, [
          'cpm',
          'cpm-emg',
          'cpm-ems',
          'cpm-ems-emg',
          'cpm-force',
        ])
      ) {
        throw new Error();
      }

      const primaryMotorKey = getMotorNameByProgram(this.originalDefinition, 'primary');

      if (!primaryMotorKey) {
        throw new Error('Missing primary motor key for CPM program');
      }

      const primaryProgramDefinition = isSpecificExerciseDefinition(this.definition, [
        'cpm',
        'cpm-emg',
        'cpm-ems',
        'cpm-ems-emg',
        'cpm-force',
      ])
        ? primaryMotorKey && isGeneratedCPMProgramDefinitionPrimary(this.definition.cpm[primaryMotorKey]!)
          ? (this.definition.cpm[primaryMotorKey] as GeneratedCPMProgramDefinitionPrimary)
          : null
        : null;

      const oldValue =
        isCPMPhasicParameterId(parameter.id) && 'phaseIndex' in parameter
          ? this.data.cpm.parameters.phases[parameter.phaseIndex ?? 0][parameter.id]?.value?.previousValue
          : this.data.cpm.parameters[parameter.id as CPMComprehensiveParameterId]?.value?.previousValue;

      const unit =
        isCPMPhasicParameterId(parameter.id) && 'phaseIndex' in parameter
          ? primaryProgramDefinition?.parameters?.phases?.[parameter.phaseIndex ?? 0]?.[parameter.id]?.unit
          : primaryProgramDefinition?.parameters?.[parameter.id as CPMComprehensiveParameterId]?.unit;

      if ((!trackerOptions.ignore && oldValue !== value) || trackerOptions.force) {
        if (typeof oldValue === 'number' || typeof oldValue === 'string') {
          exerciseActionTracker.add('parameter-adjustment', `cpm-${parameter.id}-change`, {
            type: 'cpm-parameter-change',
            description: 'trainingReport.exerciseTimeline.events.parameter-change',
            paramDescription: `training.settings.parameters.${parameter.id}`,
            from: oldValue,
            to: value,
            time: Date.now(),
            unit,
          });
        }
      }

      switch (parameter.id) {
        case 'durationBasedOn':
          assertString(value, 'durationBasedOn');
          this.data.cpm.setDurationBasedOn(value as DurationBasedOn);
          this.initializeParameters();
          break;
        case 'triggeringType':
          assertString(value, parameter.id);
          this.data.cpm.setTriggeringType(value as TriggeringType);
          this.initializeParameters();
          break;
        case 'maxTime':
          assertNumber(value, 'maxTime');
          this.data.cpm.setMaxTime(value);
          break;
        case 'repetitions':
          assertNumber(value, 'repetitions');
          this.data.cpm.setRepetition(value, parameter.phaseIndex);
          break;
        case 'speed':
          assertNumber(value, 'speed');
          this.data.cpm.setSpeed(value, parameter.phaseIndex);
          break;
        case 'maxBackwardForceLimit':
          assertNumber(value, 'maxBackwardForceLimit');
          this.data.cpm.setMaxBackwardForceLimit(value);
          break;
        case 'time':
          assertNumber(value, 'time');
          this.data.cpm.setTime(value, parameter.phaseIndex);
          break;
        case 'pauseTimeInROMMax':
          assertNumber(value, 'pauseTimeInROMMax');
          this.data.cpm.setPauseTimeInROMMax(value, parameter.phaseIndex);
          break;
        case 'pauseTimeInROMMin':
          assertNumber(value, 'pauseTimeInROMMin');
          this.data.cpm.setPauseTimeInROMMin(value, parameter.phaseIndex);
          break;
        case 'movementType':
          assertString(value, 'movementType');
          this.data.cpm.setMovementType(value as MovementType, parameter.phaseIndex);
          break;
        case 'forceTriggerSource':
          assertString(value, 'forceTriggerSource');
          this.data.cpm.setForceTriggerSource(value as SensorsName, parameter.phaseIndex);
          break;
        case 'triggeringMethod':
          assertString(value, 'triggeringMethod');
          this.data.cpm.settriggeringMethod(value as triggeringMethod, parameter.phaseIndex);
          break;

        default:
          break;
      }
    }
    if (parameter.type === 'cam') {
      if (!this.data?.cam) {
        throw new Error('Cannot set parameter for CAM Program. No CAM definition.');
      }

      if (
        !isSpecificGeneratedExerciseDefinition(this.originalDefinition, [
          'cam-isokinetic',
          'cam-torque',
          'cam-turn-key',
          'cam-game',
        ])
      ) {
        throw new Error();
      }

      const primaryMotorKey = getMotorNameByProgram(this.originalDefinition, 'primary');

      if (!primaryMotorKey) {
        throw new Error('Missing primary motor key for CAM program');
      }

      const oldValue =
        isCAMPhasicParameterId(parameter.id) && 'phaseIndex' in parameter
          ? this.data.cam.parameters.phases[parameter.phaseIndex ?? 0][parameter.id]?.value?.previousValue
          : this.data.cam.parameters[parameter.id as CPMComprehensiveParameterId]?.value?.previousValue;

      const camExerciseDefinition = isSpecificExerciseDefinition(this.definition, [
        'cam-isokinetic',
        'cam-torque',
        'cam-turn-key',
      ])
        ? primaryMotorKey && isGeneratedCAMProgramDefinitionPrimary(this.definition.cam[primaryMotorKey]!)
          ? (this.definition.cam[primaryMotorKey] as GeneratedCAMProgramDefinitionPrimary)
          : null
        : null;

      const unit =
        isCPMPhasicParameterId(parameter.id) && 'phaseIndex' in parameter
          ? camExerciseDefinition?.parameters?.phases?.[parameter.phaseIndex ?? 0]?.[parameter.id]?.unit
          : camExerciseDefinition?.parameters?.[parameter.id as CAMComprehensiveParameterId]?.unit;

      if (
        !trackerOptions.ignore &&
        (typeof oldValue === 'number' || typeof oldValue === 'string') &&
        oldValue !== value
      ) {
        exerciseActionTracker.add('parameter-adjustment', `cam-${parameter.id}-change`, {
          type: 'cam-parameter-change',
          description: 'trainingReport.exerciseTimeline.events.parameter-change',
          paramDescription: `training.settings.parameters.${parameter.id}`,
          from: oldValue,
          to: value,
          time: Date.now(),
          unit,
        });
      }

      switch (parameter.id) {
        case 'durationBasedOn':
          assertString(value, parameter.id);
          this.data.cam.setDurationBasedOn(value as DurationBasedOn);
          this.initializeParameters();
          break;
        case 'maxTime':
          assertNumber(value, 'maxTime');
          this.data.cam.setMaxTime(value);
          break;
        case 'repetitions':
          assertNumber(value, 'repetitions');
          this.data.cam.setRepetition(value, parameter.phaseIndex);
          break;
        case 'maxSpeed':
          assertNumber(value, 'maxSpeed');
          this.data.cam.setMaxSpeed(value, parameter.phaseIndex);
          break;
        case 'maxTorque':
          assertNumber(value, 'maxSpeed');
          this.data.cam.setMaxTorque(value);
          break;
        case 'maxBackwardForceLimit':
          assertNumber(value, 'maxSpeed');
          this.data.cam.setMaxBackwardForceLimit(value);
          break;
        case 'time':
          assertNumber(value, 'time');
          this.data.cam.setTime(value, parameter.phaseIndex);
          break;
        case 'activeMovementDirection':
          assertString(value, 'activeMovementDirection');
          this.data.cam.setActiveMovementDirection(value as CAMActiveMovementDirection, parameter.phaseIndex);
          break;
        case 'forceSource':
          assertString(value, 'forceSource');
          this.data.cam.setForceSource(value as SensorsName, parameter.phaseIndex);
          break;
        case 'deadband':
          assertNumber(value, 'deadband');
          this.data.cam.setDeadband(value, parameter.phaseIndex);
          break;
        default:
          break;
      }
    }
    if (parameter.type === 'game') {
      if (!this.data?.game) {
        throw new Error('Cannot set parameter for Game Program. No Game definition.');
      }
      const oldValue =
        isGamePhasicParameterId(parameter.id) &&
        'phaseIndex' in parameter &&
        this.data.game.parameters.phases[parameter.phaseIndex ?? 0][parameter.id]?.value?.previousValue;

      const gameDefinition = isGameExerciseDefinition(this.definition) ? this.definition.game : null;

      const unit =
        isGamePhasicParameterId(parameter.id) &&
        'phaseIndex' in parameter &&
        gameDefinition?.parameters?.phases?.[parameter.phaseIndex ?? 0]?.[parameter.id]?.unit;

      if (
        !trackerOptions.ignore &&
        (typeof oldValue === 'number' || typeof oldValue === 'string' || typeof oldValue === 'boolean') &&
        oldValue !== value
      ) {
        exerciseActionTracker.add('parameter-adjustment', `game-${parameter.id}-change`, {
          type: 'game-parameter-change',
          description: 'trainingReport.exerciseTimeline.events.parameter-change',
          paramDescription: `training.settings.parameters.${parameter.id}`,
          from: oldValue ? oldValue : 'no',
          to: value,
          time: Date.now(),
          unit: `${unit}`,
        });
      }

      switch (parameter.id) {
        case 'speed':
          assertNumber(value, 'speed');
          this.data.game.setSpeed(value);
          break;
        case 'routeWidth':
          assertNumber(value, 'routeWidth');
          this.data.game.setRouteWidth(value, parameter.phaseIndex);
          break;
        case 'oponent':
          assertString(value, 'oponent');
          this.data.game.setOponent(value as 'yes' | 'no', parameter.phaseIndex);
          break;
        case 'blockers':
          assertString(value, 'blockers');
          this.data.game.setBlockers(value as BlockerValue, parameter.phaseIndex);
          break;
        case 'gameBackground':
          assertString(value, 'background');
          this.data.game.setGameBackground(value as GameBackgrounds, parameter.phaseIndex);
          break;
        case 'carColor':
          assertString(value, 'carColor');
          this.data.game.setCarColor(value as CarColors, parameter.phaseIndex);
          break;
        case 'gameSteeringMode':
          assertString(value, 'gameSteeringMode');
          this.data.game.setGameSteeringMode(value as GameSteeringModes, parameter.phaseIndex);
          break;
        case 'lives':
          assertNumber(value, 'lives');
          this.data.game.setLives(value, parameter.phaseIndex);
          break;
        case 'repetitionRandomness':
          assertString(value, 'repetitionRandomness');
          this.data.game.setRepetitionRandomness(value as 'yes' | 'no');
          break;
        default:
          break;
      }
    }

    if (parameter.type === 'ems') {
      if (!this.data?.ems) {
        throw new Error('Cannot set parameter for EMS Program. No EMS definition.');
      }
      const oldValue =
        isEMSPhasicParameterId(parameter.id) && 'phaseIndex' in parameter
          ? this.data.ems.parameters.phases[parameter.phaseIndex ?? 0][parameter.id]?.value.previousValue
          : this.data.ems.parameters[parameter.id as EMSComprehensiveParameterId]?.value.previousValue;

      const cpmExerciseDefinition = isSpecificExerciseDefinition(this.definition, [
        'cpm',
        'cpm-emg',
        'cpm-ems',
        'cpm-ems-emg',
        'cpm-force',
      ])
        ? this.definition
        : null;

      // get a unit always from phase 0, program doesn't have to have more than one ems phases
      const unit =
        isEMSPhasicParameterId(parameter.id) && 'phaseIndex' in parameter
          ? cpmExerciseDefinition?.ems?.parameters?.phases?.[0]?.[parameter.id]?.unit
          : cpmExerciseDefinition?.ems?.parameters?.[parameter.id as EMSComprehensiveParameterId]?.unit;

      if ((!trackerOptions.ignore && oldValue !== value) || trackerOptions.force) {
        if (typeof oldValue === 'number' || typeof oldValue === 'string') {
          exerciseActionTracker.add('parameter-adjustment', `ems-${parameter.id}-change`, {
            type: 'ems-parameter-change',
            description: 'trainingReport.exerciseTimeline.events.parameter-change',
            paramDescription: `training.settings.parameters.${parameter.id}`,
            from: oldValue,
            to: value,
            time: Date.now(),
            unit,
            channel: 0,
          });
        }
      }

      switch (parameter.id) {
        case 'durationBasedOn':
          assertString(value, parameter.id);
          this.data.ems.setDurationBasedOn(value as DurationBasedOn);
          this.initializeParameters();
          break;
        case 'phaseRepetition':
          assertNumber(value, parameter.id);
          this.data.ems.setPhaseRepetition(value);
          break;
        case 'fallTime':
          assertNumber(value, parameter.id);
          this.data.ems.setFallTime(value, parameter.phaseIndex);
          break;
        case 'pauseTime':
          assertNumber(value, parameter.id);
          this.data.ems.setPauseTime(value, parameter.phaseIndex);
          break;
        case 'plateauTime':
          assertNumber(value, parameter.id);
          this.data.ems.setPlateauTime(value, parameter.phaseIndex);
          break;
        case 'riseTime':
          assertNumber(value, parameter.id);
          this.data.ems.setRiseTime(value, parameter.phaseIndex);
          break;
        case 'runTime':
          assertNumber(value, parameter.id);
          this.data.ems.setRunTime(value, parameter.phaseIndex);
          break;
        case 'pulseFrequency':
          assertNumber(value, parameter.id);
          this.data.ems.setPulseFrequency(value, parameter.phaseIndex);
          break;
        case 'pulseWidth':
          assertNumber(value, parameter.id);
          this.data.ems.setPulseWidth(value, parameter.phaseIndex);
          break;
        default:
          break;
      }
    }
  }

  commit() {
    Object.values(this.data).forEach(setting => {
      if (setting.parameters.phases) {
        setting.parameters.phases.forEach(v =>
          Object.values(v).forEach(p => {
            p.peek().previousValue = p.peek().currentValue;
          }),
        );
      }
      Object.entries(setting.parameters).forEach(([k, v]) => {
        if (k !== 'phases') {
          v.peek().previousValue = v.peek().currentValue;
        }
      });
    });
    this._settingStatus.value = 'commited';
  }

  undo() {
    Object.values(this.data).forEach(setting => {
      if (setting.parameters.phases) {
        setting.parameters.phases.forEach(v =>
          Object.values(v).forEach(p => {
            p.peek().currentValue = p.peek().previousValue;
          }),
        );
      }
      Object.entries(setting.parameters).forEach(([k, v]) => {
        if (k !== 'phases') {
          v.peek().currentValue = v.peek().previousValue;
        }
      });
    });
    this._settingStatus.value = 'not-commited';
  }

  export() {
    const definition = structuredClone(this.originalDefinition) as ExerciseDefinition;
    this.updateDefinition(definition, true);
    this.commit();

    return definition;
  }
}
