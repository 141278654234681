import React from 'react';
import { Box, Container, Flex, ListItem, UnorderedList } from '@chakra-ui/react';
import { ChannelConnectionQuality } from '@egzotech/exo-session/features/cable';
import { getMuscleName } from 'config/muscles';
import { __ } from 'helpers/i18n';
import { logger } from 'helpers/logger';
import { CalibrationFlow, ExerciseType } from 'libs/exo-session-manager/core';
import { BodyPartMuscle, ProgressStep } from 'types/exercise';

import { MainButton } from 'components/buttons/MainButton';
import { ErrorOutline } from 'components/icons';
import { TranslateText } from 'components/texts/TranslateText';

import { CalibrationFlowBackButton } from './CalibrationFlowBackButton';
import { CalibrationFlowNextButton } from './CalibrationFlowNextButton';

interface StepStatusProps {
  step: ProgressStep;
  currentMuscle: BodyPartMuscle;
  electrodesConnectionState: {
    allDisconnected: boolean;
    allConnected: boolean;
    allCorrect: boolean;
  };
  flow: CalibrationFlow;
  exerciseType: ExerciseType;
  currentElectrode: number;
  onActivatePort(): void;
  onBackClick(): void;
  onNextClick(): void;
  isPelvicElectrode?: boolean;
}

export const ConnectElectrodesStepStatus = ({
  step: currentElectrodeStatus,
  currentMuscle,
  electrodesConnectionState,
  flow,
  exerciseType,
  currentElectrode,
  onActivatePort,
  onBackClick,
  onNextClick,
  isPelvicElectrode,
}: StepStatusProps) => {
  const currentState = getCurrentStatusName(currentElectrodeStatus);
  let exerciseComment = 'default';

  switch (exerciseType) {
    case 'cpm-emg':
    case 'cam-game':
    case 'emg':
    case 'emg-pelvic':
      exerciseComment = 'emg';
      break;

    case 'cpm-ems':
    case 'cpm-ems-emg':
    case 'ems':
    case 'ems-emg':
      exerciseComment = 'ems';
      break;

    default:
      logger.warn('ConnectElectrodesStepStatus', 'Missing comment type - switching to default');
  }

  exerciseComment = exerciseComment + '-' + (currentElectrode === 0 ? '1' : '2');

  const channelInstructionKey = isPelvicElectrode ? 'pelvic' : 'info';

  return (
    <Container variant="electrodesInfo">
      <TranslateText
        variant="openSans36Bold"
        letterSpacing="0"
        text={`exercise.electrodesConnection.${currentState}.heading`}
        replace={{
          muscleName: __(`muscles.${getMuscleName(currentMuscle.muscleId)}`, null, 'muscles.unknown'),
        }}
        mb="10"
      />
      {currentState !== 'connected' && (
        <Box mt="auto" mb="14">
          <TranslateText
            variant="openSans24Bold"
            text={`exercise.electrodesConnection.${currentState}.description`}
            replace={{ channelIndex: currentMuscle.muscleId }}
            mb="7"
            letterSpacing="0"
          />

          <UnorderedList textAlign="start" mt="6">
            {currentState === 'waiting' && !isPelvicElectrode && (
              <ListItem mb="2">
                <TranslateText
                  variant="openSans24"
                  letterSpacing="0"
                  text={`exercise.electrodesConnection.${currentState}.exerciseType.${exerciseComment}`}
                />
              </ListItem>
            )}
            <ListItem mb="2">
              <TranslateText
                variant="openSans24"
                letterSpacing="0"
                replace={{
                  muscleName: isPelvicElectrode
                    ? __(
                        `exercise.electrodesConnection.${currentState}.${channelInstructionKey}.${
                          currentMuscle.muscleId === 'pelvic.no-side.vagina' ? 'vaginal' : 'rectal'
                        }`,
                      )
                    : '',
                }}
                text={`exercise.electrodesConnection.${currentState}.${channelInstructionKey}.1`}
              />
            </ListItem>
            <ListItem mb="2">
              <TranslateText
                variant="openSans24"
                letterSpacing="0"
                text={`exercise.electrodesConnection.${currentState}.${channelInstructionKey}.2`}
              />
            </ListItem>
            <ListItem mb="2">
              <TranslateText
                variant="openSans24"
                letterSpacing="0"
                text={`exercise.electrodesConnection.${currentState}.${channelInstructionKey}.3`}
              />
            </ListItem>

            {isPelvicElectrode && currentState === 'warning' ? null : (
              <ListItem mb="2">
                <TranslateText
                  variant="openSans24"
                  letterSpacing="0"
                  text={`exercise.electrodesConnection.${currentState}.${channelInstructionKey}.4`}
                />
              </ListItem>
            )}
          </UnorderedList>
        </Box>
      )}
      <Flex justifyContent="space-between" mt="auto">
        {electrodesConnectionState.allDisconnected ? (
          <CalibrationFlowBackButton flow={flow} />
        ) : (
          <MainButton
            variant={{ lg: 'smOutlinedPrimaryButton', '2xl': 'mdOutlinedPrimaryButton' }}
            text="common.back"
            onClick={onBackClick}
            data-testid="electrodes-info-back-button"
          />
        )}
        {electrodesConnectionState.allConnected ? (
          <CalibrationFlowNextButton
            isFormValidation
            validationPassed={electrodesConnectionState.allCorrect}
            flow={flow}
            onNext={onActivatePort}
          />
        ) : (
          <MainButton
            type="submit"
            isDisabled={currentElectrodeStatus.status === ChannelConnectionQuality.NONE}
            variant={
              currentElectrodeStatus.status === ChannelConnectionQuality.POOR
                ? {
                    lg: 'smWarningButton',
                    '2xl': 'mdWarningButton',
                  }
                : {
                    lg: 'smPrimaryButton',
                    '2xl': 'mdPrimaryButton',
                  }
            }
            text="common.next"
            onClick={onNextClick}
            rightIcon={currentElectrodeStatus.status === ChannelConnectionQuality.POOR ? <ErrorOutline /> : undefined}
            data-testid="electrodes-info-next-button"
          />
        )}
      </Flex>
    </Container>
  );
};

const getCurrentStatusName = (step: ProgressStep) => {
  switch (step.status) {
    case ChannelConnectionQuality.WELL:
      return 'connected';
    case ChannelConnectionQuality.NONE:
      return step.timedOut ? 'disconnected' : 'waiting';
    case ChannelConnectionQuality.POOR:
      return 'warning';
  }
};
