export type Channel = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

const colors = {
  // colors from mockups
  egzotechPrimaryColor: '#0095DA',
  stellaBioPrimaryColor: '#66CCCC',
  stellaBioSecondaryColor: '#E6F8F8',

  textColor: '#323E4A',
  textLightColor: '#fff',
  headerTextColor: '#1F2933',

  black: '#000',

  chartAxisColor: '#ddd',

  backgroundColor: '#F1F5F8',
  skeletonDarkColor: '#E4E7EC',
  appleColor: '#1D293F',
  noteBackgroundColor: '#C4ECFF',
  inputDisabledBackgroundColor: '#F4F5F7',
  scrollbarGutterColor: '#B1B9C2',
  scrollbarBackgroundColor: '#D7DADE',
  skeletonStartColor: '#F4F5F7',
  skeletonEndColor: '#E4E7EC',
  progressBarColor: '#0095DA',

  successColor: '#11C991',
  warningColor: '#FFDD00',
  errorColor: '#FF4A75',
  errorMediumColor: '#FF92AC',
  errorLightColor: '#FFE9EE',
  warningMediumColor: '#f3e845',
  warningNotificationColor: '#F6AD55',

  arrowDownColor: '#F687B3',
  deviceNameColorInStatisticsReport: '#63CCCB',

  channel: {
    1: '#AB2524',
    2: '#2874B2',
    3: '#48A43F',
    4: '#83639D',
    5: '#7B5141',
    6: '#282828',
    7: '#EEC900',
    8: '#EC7C25',
    light: {
      1: '#D59191',
      2: '#93B9D8',
      3: '#A3D19E',
      4: '#C1B0CE',
      5: '#BCA79F',
      6: '#939393',
      7: '#F6E47F',
      8: '#F5BD91',
    },
  },

  'channel.1': '#AB2524',
  'channel.2': '#2874B2',
  'channel.3': '#48A43F',
  'channel.4': '#83639D',
  'channel.5': '#7B5141',
  'channel.6': '#282828',
  'channel.7': '#EEC900',
  'channel.8': '#EC7C25',
  'channel.light.1': '#D59191',
  'channel.light.2': '#93B9D8',
  'channel.light.3': '#A3D19E',
  'channel.light.4': '#C1B0CE',
  'channel.light.5': '#BCA79F',
  'channel.light.6': '#939393',
  'channel.light.7': '#F6E47F',
  'channel.light.8': '#F5BD91',

  // Avatars needs to be specified like this, because we want to use it
  // as an array and it needs to be compatible with ThemeOverride definition
  avatar: {
    0: '#FF9AA2',
    1: '#FEB7B1',
    2: '#FFDAC0',
    3: '#E2F0CC',
    4: '#B5EAD6',
    5: '#C7EAE9',
    6: '#C7DBEA',
    7: '#C7CEEA',
    8: '#E2C7EA',
    9: '#EAC7DC',
    10: '#CEC2AE',
    11: '#B7B7B7',
  } as { [key: string]: string },
  avatarAnonymous: '#E4E7EC',

  blueGradient: 'linear-gradient(90deg, #0095DA 0%, #66CCCC 100%)',

  // default colors defined by chakra ui
  whiteAlpha: {
    0: 'rgba(255, 255, 255, 0)',
    50: 'rgba(255, 255, 255, 0.04)',
    100: 'rgba(255, 255, 255, 0.06)',
    200: 'rgba(255, 255, 255, 0.08)',
    250: 'rgba(255, 255, 255, 0.1)',
    300: 'rgba(255, 255, 255, 0.16)',
    400: 'rgba(255, 255, 255, 0.24)',
    500: 'rgba(255, 255, 255, 0.36)',
    600: 'rgba(255, 255, 255, 0.48)',
    700: 'rgba(255, 255, 255, 0.64)',
    800: 'rgba(255, 255, 255, 0.80)',
    900: 'rgba(255, 255, 255, 0.92)',
  },

  gray: {
    50: '#F7FAFC',
    100: '#EDF2F7',
    200: '#E2E8F0',
    300: '#CBD5E0',
    400: '#A0AEC0',
    500: '#718096',
    600: '#4A5568',
    700: '#2D3748',
    800: '#1A202C',
    900: '#171923',
  },

  grayAlpha: {
    300: 'rgba(203, 213, 224, 0.7)',
  },

  yellow: {
    50: '#FFFFF0',
    100: '#FEFCBF',
    200: '#FAF089',
    300: '#F6E05E',
    400: '#ECC94B',
    500: '#D69E2E',
    600: '#B7791F',
    700: '#975A16',
    800: '#744210',
    900: '#5F370E',
  },

  facebook: {
    50: '#E8F4F9',
    100: '#D9DEE9',
    200: '#B7C2DA',
    300: '#6482C0',
    400: '#4267B2',
    500: '#385898',
    600: '#314E89',
    700: '#29487D',
    800: '#223B67',
    900: '#1E355B',
  },
};

export function hexToRgb(hex: string) {
  hex = hex.replace(/^#/, '');
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map(char => char + char)
      .join('');
  }
  const bigint = parseInt(hex, 16);
  return { r: (bigint >> 16) & 255, g: (bigint >> 8) & 255, b: bigint & 255 };
}

export default colors;
