import { Logger } from '@egzotech/universal-logger-js';
import { isNil } from 'helpers/object';
import { Signal, signal } from 'helpers/signal';

import {
  BlockerValue,
  CarColors,
  ExerciseDefinition,
  GameBackgrounds,
  GameComprehensiveParameters,
  GamePhasicParameters,
  GameSteeringModes,
  GeneratedGameLikeExerciseDefinition,
  isGameExerciseDefinition,
} from '../types/GeneratedExerciseDefinition';

import { GamePhasicParameterId, SettingsParameters, SettingsRequiredMethods } from './SettingsBuilder';

export type GameSignalParameter = {
  [key in GamePhasicParameterId]: Signal<SettingsParameters<GamePhasicParameters>[key]>;
};

export type GameSignalParameters = {
  phases: GameSignalParameter[];
  repetitionRandomness?: Signal<SettingsParameters<GameComprehensiveParameters>['repetitionRandomness']>;
};

export default class GameSettings implements SettingsRequiredMethods {
  private _parameters: GameSignalParameters = {
    phases: [],
  };

  get hasDefinitionParameters() {
    return this.originalDefinition.game?.parameters.phases.length > 0;
  }

  static readonly logger = Logger.getInstance('GameSettings');

  constructor(
    private readonly originalDefinition: GeneratedGameLikeExerciseDefinition,
    initialDefinition = originalDefinition,
  ) {
    this._parameters.phases = originalDefinition.game.parameters.phases.map((phase, i) => ({
      speed: signal(
        {
          currentValue: initialDefinition.game.program.phases[i].speed ?? phase.speed?.default ?? 0,
          default: phase?.speed?.default ?? 0,
          values: phase?.speed?.values ?? [],
          blockAfterStart: phase?.speed?.blockAfterStart ?? false,
          previousValue: phase.speed?.default ?? 0,
        },
        'GameSettings._parameters.phases.speed',
      ),
      routeWidth: signal(
        {
          currentValue: initialDefinition.game.program.phases[i].routeWidth ?? phase.routeWidth?.default ?? 0,
          default: phase?.routeWidth?.default ?? 0,
          values: phase?.routeWidth?.values ?? [],
          blockAfterStart: phase?.routeWidth?.blockAfterStart ?? false,
          previousValue: phase.routeWidth?.default ?? 0,
        },
        'GameSettings._parameters.phases.routeWidth',
      ),
      oponent: signal(
        {
          currentValue: initialDefinition.game.program.phases[i].oponent ?? phase.oponent?.default ?? 'no',
          default: phase?.oponent?.default ?? 'no',
          values: phase?.oponent?.values ?? [],
          blockAfterStart: phase?.oponent?.blockAfterStart ?? false,
          previousValue: phase.oponent?.default ?? 'no',
        },
        'GameSettings._parameters.phases.oponent',
      ),
      blockers: signal(
        {
          currentValue:
            initialDefinition.game.program.phases[i].blockers ??
            phase.blockers?.default ??
            'driving-game-blokers-normal',
          default: phase?.blockers?.default ?? 'driving-game-blokers-normal',
          values: phase?.blockers?.values ?? [],
          blockAfterStart: phase?.blockers?.blockAfterStart ?? false,
          previousValue: phase.blockers?.default ?? 'driving-game-blokers-normal',
        },
        'GameSettings._parameters.phases.blockers',
      ),
      gameBackground: signal(
        {
          currentValue:
            initialDefinition.game.program.phases[i].gameBackground ?? phase.gameBackground?.default ?? 'mountains',
          default: phase?.gameBackground?.default ?? 'mountains',
          values: phase?.gameBackground?.values ?? [],
          blockAfterStart: phase?.gameBackground?.blockAfterStart ?? false,
          previousValue: phase.gameBackground?.default ?? 'mountains',
        },
        'GameSettings._parameters.phases.gameBackground',
      ),
      carColor: signal(
        {
          currentValue: initialDefinition.game.program.phases[i].carColor ?? phase.carColor?.default ?? 'red',
          default: phase?.carColor?.default ?? 'red',
          values: phase?.carColor?.values ?? [],
          blockAfterStart: phase?.carColor?.blockAfterStart ?? false,
          previousValue: phase.carColor?.default ?? 'red',
        },
        'GameSettings._parameters.phases.carColor',
      ),
      gameSteeringMode: signal(
        {
          currentValue:
            initialDefinition.game.program.phases[i].gameSteeringMode ??
            phase.gameSteeringMode?.default ??
            'normalControl',
          default: phase?.gameSteeringMode?.default ?? 'normalControl',
          values: phase?.gameSteeringMode?.values ?? [],
          blockAfterStart: phase?.gameSteeringMode?.blockAfterStart ?? false,
          previousValue: phase.gameSteeringMode?.default ?? 'normalControl',
        },
        'GameSettings._parameters.phases.gameSteeringMode',
      ),
      lives: signal(
        {
          currentValue: initialDefinition.game.program.phases[i].lives ?? phase.lives?.default ?? 3,
          default: phase?.lives?.default ?? 3,
          values: phase?.lives?.values ?? [],
          blockAfterStart: phase?.lives?.blockAfterStart ?? false,
          previousValue: phase.lives?.default ?? 3,
        },
        'GameSettings._parameters.phases.lives',
      ),
    }));
    this._parameters.repetitionRandomness = signal(
      {
        currentValue: initialDefinition.game.program.repetitionRandomness ?? 'no',
        default: originalDefinition.game.parameters.repetitionRandomness?.default ?? 'no',
        values: originalDefinition.game.parameters.repetitionRandomness?.values ?? [],
        blockAfterStart: originalDefinition.game.parameters.repetitionRandomness?.blockAfterStart ?? true,
        previousValue: originalDefinition.game.parameters.repetitionRandomness?.default ?? 'no',
      },
      'GameSettings._parameters.repetitionRandomness',
    );
  }

  get parameters() {
    return this._parameters;
  }

  private validateGameParameters(phaseIndex: number, key: keyof GamePhasicParameters, value: number | string) {
    if (phaseIndex < 0 || (phaseIndex >= this._parameters.phases.length ?? 0) || !this._parameters.phases[phaseIndex]) {
      throw new Error(`There is no phaseIndex with given index: ${phaseIndex} for this exercise`);
    }
    if (!this._parameters.phases[phaseIndex][key]?.peek()?.values?.includes(value as never)) {
      throw new Error(
        `Cannot set given value for key ${key}: ${value}. It must be one of the value from: [${
          this._parameters.phases[phaseIndex][key]?.peek()?.values
        }]`,
      );
    }
  }
  setSpeed(speed: number, phaseIndex = 0) {
    this.validateGameParameters(phaseIndex, 'speed', speed);
    this._parameters.phases[phaseIndex].speed!.value = {
      ...this._parameters.phases[phaseIndex].speed!.peek(),
      currentValue: speed,
    };
  }

  setRouteWidth(routeWidth: number, phaseIndex = 0) {
    this.validateGameParameters(phaseIndex, 'routeWidth', routeWidth);
    this._parameters.phases[phaseIndex].routeWidth!.value = {
      ...this._parameters.phases[phaseIndex].routeWidth!.peek(),
      currentValue: routeWidth,
    };
  }

  setOponent(oponent: 'yes' | 'no', phaseIndex = 0) {
    this.validateGameParameters(phaseIndex, 'oponent', oponent);
    this._parameters.phases[phaseIndex].oponent!.value = {
      ...this._parameters.phases[phaseIndex].oponent!.peek(),
      currentValue: oponent,
    };
  }

  setRepetitionRandomness(enable: 'yes' | 'no') {
    if (!this._parameters.repetitionRandomness) {
      throw new Error('The repetition randomness parameter is not defined');
    }
    this._parameters.repetitionRandomness.value = {
      ...this._parameters.repetitionRandomness.peek(),
      currentValue: enable,
    };
  }

  setBlockers(blockers: BlockerValue, phaseIndex = 0) {
    this.validateGameParameters(phaseIndex, 'blockers', blockers);
    this._parameters.phases[phaseIndex].blockers!.value = {
      ...this._parameters.phases[phaseIndex].blockers!.peek(),
      currentValue: blockers,
    };
  }

  setGameBackground(background: GameBackgrounds, phaseIndex = 0) {
    this.validateGameParameters(phaseIndex, 'gameBackground', background);
    this._parameters.phases[phaseIndex].gameBackground!.value = {
      ...this._parameters.phases[phaseIndex].gameBackground!.peek(),
      currentValue: background,
    };
  }
  setCarColor(carColor: CarColors, phaseIndex = 0) {
    this.validateGameParameters(phaseIndex, 'carColor', carColor);
    this._parameters.phases[phaseIndex].carColor!.value = {
      ...this._parameters.phases[phaseIndex].carColor!.peek(),
      currentValue: carColor,
    };
  }
  setGameSteeringMode(gameSteeringMode: GameSteeringModes, phaseIndex = 0) {
    this.validateGameParameters(phaseIndex, 'gameSteeringMode', gameSteeringMode);
    this._parameters.phases[phaseIndex].gameSteeringMode!.value = {
      ...this._parameters.phases[phaseIndex].gameSteeringMode!.peek(),
      currentValue: gameSteeringMode,
    };
  }

  setLives(lives: number, phaseIndex = 0) {
    this.validateGameParameters(phaseIndex, 'lives', lives);
    this._parameters.phases[phaseIndex].lives!.value = {
      ...this._parameters.phases[phaseIndex].lives!.peek(),
      currentValue: lives,
    };
  }

  updateDefinition(definition: ExerciseDefinition) {
    if (!isGameExerciseDefinition(definition)) {
      throw new Error('Cannot update non game definition in GameSettings');
    }

    this._parameters.phases.forEach((parameter, phaseIndex) => {
      Object.entries(parameter).forEach(([k, v]) => {
        if (v?.peek()?.currentValue !== undefined) {
          // here is any because typescript suffers from a lack of dynamic typing
          (definition.game.program.phases[phaseIndex] as any)[k as GamePhasicParameterId] = v.peek().currentValue;
        }
      });
    });

    const comprehensiveParameters = Object.fromEntries(
      Object.entries(this._parameters)
        .filter(([k]) => k !== 'phases')
        .map(([k, v]) => [k, v]),
    );

    for (const key in comprehensiveParameters) {
      const parameter = comprehensiveParameters[key];
      if (
        !isNil(parameter) &&
        definition.game.program[key as keyof typeof definition.game.program] &&
        !Array.isArray(parameter) &&
        !isNil(parameter.peek().currentValue)
      ) {
        // here is any because typescript suffers from a lack of dynamic typing
        (definition.game.program as any)[key as keyof typeof definition.game.program] = parameter.peek().currentValue;
      }
    }
  }
}
