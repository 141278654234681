import { ReactNode, useEffect } from 'react';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { API_URL } from 'config/apiUrl';
import { logger } from 'helpers/logger';
import { useSignal } from 'helpers/signal';
import { EGZOTechHostApi } from 'services/EGZOTechHostApi';

import { SignalRContext } from '../contexts/SignalRContext';

interface SignalRProviderProps {
  endpoint: string;
  children: ReactNode;
}

export type ButtonStatus = 'Pressed' | 'Released' | 'Unknown';

export function SignalRProvider({ endpoint, children }: SignalRProviderProps) {
  const displayButton = useSignal<ButtonStatus>('Unknown', 'SignalR.buttonStateChanged');

  useEffect(() => {
    if (!EGZOTechHostApi.instance?.options?.signalRAvailable) {
      return;
    }

    const connection = new HubConnectionBuilder()
      .withUrl(API_URL + endpoint)
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Warning)
      .build();

    connection
      .start()
      .then(() => logger.info('SignalRProvider', 'Connected to SignalR server'))
      .catch(error => logger.error('SignalRProvider', 'Error connecting to SignalR server:', error));

    connection.on('buttonStateChanged', (value: ButtonStatus) => {
      displayButton.value = value;
    });

    return () => {
      connection.stop().catch(error => logger.error('SignalRProvider', 'Error while stopping SigalR server', error));
    };
  }, [displayButton, endpoint]);

  return (
    <SignalRContext.Provider
      value={{
        displayButton,
      }}
    >
      {children}
    </SignalRContext.Provider>
  );
}
