import { Box, Center, HStack, Image, ModalBody, ModalContent, VStack } from '@chakra-ui/react';

import { MainButton } from 'components/buttons/MainButton';
import { MainHeading } from 'components/texts/MainHeading';
import { TranslateText } from 'components/texts/TranslateText';

import { ModalProps } from './Modal';

export type DescriptionText = string;
export type DescriptionProps = string;
export type DescriptionMap = [string, Record<DescriptionText, DescriptionProps>][];

interface UpdateFirmwareNotificationModalProps extends ModalProps {
  heading: string;
  data: DescriptionMap;
}

export const UpdateFirmwareNotificationModal = ({ heading, data, close }: UpdateFirmwareNotificationModalProps) => {
  return (
    <ModalContent minW={{ base: '4xl', '2xl': '6xl' }} minH={{ base: 'xl', '2xl': '3xl' }} borderRadius="rLg" p="2">
      <ModalBody display="flex" w="full" h="full" gap="2" justifyContent="space-around" flexDirection="column">
        <Center>
          <MainHeading variant="subheading36" text={heading} />
        </Center>
        <HStack textAlign="center" w="full" h="xs" spacing="3">
          {data.map(([image, description], i) => {
            return (
              <VStack w="full" h="full" justify="start" align="center" key={i}>
                <Image src={image} maxH={{ base: '3xs', '2xl': 'sm' }} maxW={{ base: '3xs', '2xl': 'sm' }} mt="4" />
                <Box h="8" w="8" borderRadius="full" backgroundColor="egzotechPrimaryColor" color="white" p="1">
                  {i + 1}
                </Box>
                <TranslateText>
                  {Object.entries(description).map(([text, props], i) => (
                    <TranslateText
                      as="span"
                      key={i}
                      variant="openSans24"
                      maxW="64"
                      maxH="64"
                      mt="2"
                      fontWeight={props}
                      text={text}
                    />
                  ))}
                </TranslateText>
              </VStack>
            );
          })}
        </HStack>
        <HStack w="full" justifyContent="space-evenly" mt="2">
          <MainButton
            text="exercise.warningScreen.confirm"
            variant="mdPrimaryButton"
            onClick={() => {
              close();
            }}
            minW="32"
          />
        </HStack>
      </ModalBody>
    </ModalContent>
  );
};
