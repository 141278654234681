import { ExoSession, Timer } from '@egzotech/exo-session';
import { ExoEMGFeature } from '@egzotech/exo-session/features/emg';

const SAMPLE_TIMEDELTA = 0.05;
export class SampleBasedTimer extends Timer {
  private _timestamps: number[] = [];
  private _channelMask: number[] = [];

  private _emgFeature: ExoEMGFeature;

  constructor(private _session: ExoSession, emgFeature?: ExoEMGFeature) {
    super();
    this._emgFeature = !emgFeature ? this._session.activate(ExoEMGFeature) : emgFeature;
  }

  onEmg(channels: { [key: number]: number }, _: { [key: number]: number }) {
    for (const channel in channels) {
      // I'm not sure we should use here _timeDelta from exo-session
      // till we add real sample time to StellaBIO. We want to visualize here
      // real time from the time of measurement which is not the same as time
      // from exo-session
      this._timestamps[channel] =
        typeof this._timestamps[channel] === 'number' ? this._timestamps[channel] + SAMPLE_TIMEDELTA : 0;
    }
  }

  setChannelMask(channelMask: number[]) {
    this._channelMask = channelMask;
  }

  get duration() {
    return this._timestamps.reduce((acc, cur) => Math.max(acc, cur), 0);
  }

  getTimestampForChannel(channel: number) {
    return channel in this._timestamps ? this._timestamps[channel] : 0;
  }

  play() {
    if (this.state === 'idle') {
      this._timestamps = [];
    }
    super.play();
    this._emgFeature.onEmg = this.onEmg.bind(this);
    this._emgFeature.enable(this._channelMask);
  }

  pause() {
    super.pause();
    this._emgFeature.onEmg = undefined;
    this._emgFeature.disable();
  }

  resume() {
    this.play();
  }
}
