import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type FirmwareState = {
  version: number | null;
};

const initialState: FirmwareState = {
  version: null,
};

const firmwareSlice = createSlice({
  name: 'firmware',
  initialState,
  reducers: {
    setVersion: (state, { payload }: PayloadAction<number>) => {
      state.version = payload;
    },
  },
});

export const { setVersion } = firmwareSlice.actions;

export default firmwareSlice.reducer;
