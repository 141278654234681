import { FC, ReactElement } from 'react';
import { Input, InputGroup, InputProps, InputRightElement } from '@chakra-ui/react';
import { __ } from 'helpers/i18n';

interface Props extends InputProps {
  width?: string;
  icon?: ReactElement;
  radius?: boolean;
  radiusSize?: string;
  iconMarginRight?: string;
}

export const MainInput: FC<Props> = ({
  placeholder,
  width,
  icon,
  radius,
  radiusSize,
  height,
  iconMarginRight: _,
  ...props
}) => {
  return (
    <InputGroup height={height} w={width ?? ['100%', null, null, '40%']} mt={['1', null, null, '0']}>
      <Input
        minH="12"
        borderRadius={radius ? radiusSize : 'rSm'}
        fontSize="lg"
        cursor="pointer"
        textColor="gray.600"
        borderColor="gray.300"
        _placeholder={{ color: 'gray.300' }}
        pl="4"
        focusBorderColor="gray.300"
        {...props}
        placeholder={__(placeholder!)}
      />
      {icon && (
        <InputRightElement color="gray.300" height="100%">
          {icon}
        </InputRightElement>
      )}
    </InputGroup>
  );
};
