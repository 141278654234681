import { useSignals } from '@preact/signals-react/runtime';
import { useSignalEffect } from 'helpers/signal';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useFirmwareModuleCheck } from 'hooks/useFirmwareModuleCheck';
import { useNavigateAfterLogout } from 'hooks/useNavigateAfterLogout';
import { useReconfigureBackendDetectMechanism } from 'hooks/useReconfigureBackendDetectMechanism';
import { useDevice } from 'libs/exo-session-manager/react';
import { useSignalR } from 'libs/exo-session-manager/react/hooks/useSignalRConnection';
import { closeModal, openModal } from 'slices/modalSlice';

import { ModalType } from './modals/Modal';
import { useEmergencyStopButtonModal } from './modals/useEmergencyStopButtonModal';

export function SystemComponents() {
  useSignals();
  const dispatch = useAppDispatch();
  const { displayButton } = useSignalR();
  const currentModal = useAppSelector(state => state.modal.name);
  const { selectedDevice } = useDevice();

  useSignalEffect(() => {
    switch (displayButton?.value) {
      case 'Pressed':
        // At the moment there are no components visible for devices other than Meissa OT
        // Remove selectedDevice?.type === 'meissa-ot' condition when adding one
        selectedDevice?.type === 'meissa-ot' && currentModal !== ModalType.SYSTEM_COMPONENTS.name
          ? dispatch(openModal({ type: ModalType.SYSTEM_COMPONENTS }))
          : dispatch(closeModal(ModalType.SYSTEM_COMPONENTS));
        break;
    }
  });

  useEmergencyStopButtonModal();
  useFirmwareModuleCheck();
  useNavigateAfterLogout();

  // Reconfigure backend device detection when app is started
  useReconfigureBackendDetectMechanism();

  return <></>;
}
