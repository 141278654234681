import { ExoSession } from '@egzotech/exo-session';
import { ExoEMGFeature } from '@egzotech/exo-session/features/emg';

import { SampleBasedTimer } from './SampleBasedTimer';
import { Recordings, SerializedRecordings, SignalRecorderController } from './SignalRecorderController';

export function serializeRecordings(recordings: Recordings): SerializedRecordings {
  const serialized: SerializedRecordings = {};

  for (const key in recordings) {
    const recordingKey = key as keyof typeof recordings;
    if (recordingKey !== 'emg') {
      serialized[recordingKey] = {
        samples: Array.from(recordings[recordingKey]!.samples),
        timePoints: Array.from(recordings[recordingKey]!.timePoints),
      };
    }
  }

  if (recordings.emg) {
    for (const channel in recordings.emg) {
      serialized.emg = {
        ...serialized.emg,
        [channel]: {
          samples: Array.from(recordings.emg[channel].samples),
          timePoints: Array.from(recordings.emg[channel].timePoints),
        },
      };
    }
  }

  return serialized;
}

export function deserializeRecordings(recordings: SerializedRecordings): Recordings {
  const deserialized: Recordings = {};

  for (const key in recordings) {
    const recordingKey = key as keyof typeof recordings;
    if (recordingKey !== 'emg') {
      deserialized[recordingKey] = {
        samples: new Float32Array(recordings[recordingKey]!.samples),
        timePoints: new Uint32Array(recordings[recordingKey]!.timePoints),
      };
    }
  }

  if (recordings.emg) {
    for (const channel in recordings.emg) {
      deserialized.emg = {
        ...deserialized.emg,
        [channel]: {
          samples: new Float32Array(recordings.emg[channel].samples),
          timePoints: new Uint32Array(recordings.emg[channel].timePoints),
        },
      };
    }
  }

  return deserialized;
}

export class EMGSegmentRecorder {
  readonly emgFeature = this.session.activate(ExoEMGFeature);
  readonly emgTimer = new SampleBasedTimer(this.session, this.emgFeature);
  readonly recorderController = new SignalRecorderController([this.emgFeature], this.channels, this.emgTimer);

  private _recordings: Recordings = {};

  get recordings() {
    return this._recordings;
  }

  constructor(readonly session: ExoSession, readonly channels: number[]) {}

  start() {
    if (!this.emgFeature.canEnable(this.channels)) {
      throw new Error('Cannot enable EMG on selected channels with given cable');
    }
    this.emgFeature.enable(this.channels);
    this.emgTimer.setChannelMask(this.channels);
    this.emgTimer.play();
    this.recorderController.start();
  }

  end() {
    this._recordings = this.recorderController.stop();
    this.emgTimer.pause();
    this.emgFeature.disable();
    this.emgFeature.onEmg = undefined;
    return serializeRecordings(this._recordings);
  }

  reset() {
    this.recorderController.reset();
    this.emgTimer.pause();
    this.emgTimer.idle();
    this.emgFeature.disable();
    this.emgFeature.onEmg = undefined;
  }

  dispose() {
    this.reset();
    this.emgFeature.dispose();
  }
}
