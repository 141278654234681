import { CalibrationFlowStateIdentifier } from '../common/CalibrationFlow';

export const emgFlow = (
  prev: CalibrationFlowStateIdentifier | null,
  next: CalibrationFlowStateIdentifier | null,
  stepIndex: number,
) => {
  return {
    'emg-calibration': {
      prev,
      next,
      stepIndex,
    },
  } as const;
};

export const emgRelaxationMeasurement = (
  prev: CalibrationFlowStateIdentifier | null,
  next: CalibrationFlowStateIdentifier | null,
  stepIndex: number,
) => {
  return {
    'emg-relaxation-measurement': {
      prev,
      next,
      stepIndex,
    },
  } as const;
};
