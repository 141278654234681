import { ApiErrorMessage, Response as RestResponse } from 'types';

import { sessionExpired } from '../services/api';

import { getErrorMessage } from './getErrorMessage';
import { errorNotification, fatalNotification } from './handleNotifications';
import { has } from './i18n';

export const handleError = async (response: Response, body?: unknown): Promise<void> => {
  if (!response) {
    errorNotification(getErrorMessage(''));
    return;
  }

  const status = response.status;
  let data: unknown;

  try {
    data = body ?? (await response.json());
  } catch (ex) {
    data = null;
  }

  if (status === 401) {
    let userLocked = false;
    if (data && typeof data === 'object' && 'payload' in data) {
      const { payload } = data as RestResponse<ApiErrorMessage>;
      const { formErrors } = payload;
      if (formErrors) {
        userLocked = formErrors['user'] === 'locked';
      }
    }
    userLocked ? null : sessionExpired();
  }

  if (!data) {
    const subtitle = `[${status}] ${response.statusText}`;
    errorNotification(getErrorMessage(''), undefined, { subtitle });
    return;
  }

  if (typeof data === 'object') {
    if (
      'error' in data &&
      typeof data.error === 'object' &&
      data.error &&
      (Array.isArray(data.error) ? data.error.length > 0 : true)
    ) {
      const errors: unknown[] = Array.isArray(data.error) ? data.error : [data.error];

      for (let i = 0; i < errors.length; i++) {
        const error = errors[i];

        if (typeof error === 'object' && error && 'id' in error && typeof error.id === 'string') {
          let message = getErrorMessage('api.' + error.id);
          let subtitle;

          if (!has(message)) {
            message = getErrorMessage('api.any');
            subtitle = error.id;
          }

          if (status >= 500) {
            fatalNotification(message, undefined, { subtitle });
          } else {
            errorNotification(message, undefined, { subtitle });
          }
        }
      }

      return;
    } else if ('title' in data) {
      const message = getErrorMessage('api.any');
      const subtitle = `[${status}] ${data.title}`;

      errorNotification(message, undefined, { subtitle });
      return;
    }
  }

  const { details, formErrors, errorCode } = data as ApiErrorMessage;

  if (formErrors) {
    const formErrorsArray = Array(formErrors);

    if (formErrorsArray.length) {
      formErrorsArray.forEach(item => {
        const keyName = Object.keys(item)[0];

        const errorName = `${keyName}.${item[keyName]}`;
        errorNotification(getErrorMessage(errorName));
      });

      return;
    }
  }

  if (details) {
    errorNotification(getErrorMessage(details));
    return;
  }
  errorNotification(getErrorMessage(errorCode));
};
