import { Timer } from '@egzotech/exo-session';
import { Logger } from '@egzotech/universal-logger-js';
import { __ } from 'helpers/i18n';
import { Signal, signal } from 'helpers/signal';
import { EMGStaticChartDataSource } from 'libs/chart-datasources/EMGChartDataSource';
import { RELAXATION_MEASUREMENT_TOTAL_TIME } from 'views/+patientId/training/+trainingId/_components/emg-calibration/EmgRelaxationMeasurement';

import { SignalRecorderController } from './SignalRecorderController';

export interface EMGSegment {
  start: number;
  end?: number;
  name?: string;
  description?: string;
  dataSource?: EMGStaticChartDataSource;
}

export type EMGSegmentUploadData = Omit<EMGSegment, 'dataSource'>;

export const segmentAnalyzableExercises = ['emg_view', 'emg_view_with_mvc', 'emg_relaxation_analysis'] as const;

export type SegmentAnalyzableExercises = (typeof segmentAnalyzableExercises)[number];

export function isExerciseSegmentAnalyzable(exerciseId: string): exerciseId is SegmentAnalyzableExercises {
  return segmentAnalyzableExercises.includes(exerciseId as SegmentAnalyzableExercises);
}

export class EmgSegmentAnalyzer {
  static readonly logger = Logger.getInstance('EmgSegmentAnalyzer');

  segments = signal<EMGSegment[]>([], 'EmgSegmentAnalyzer.segments');

  private currentSegmentStart: number | null = null;

  constructor(
    public readonly exerciseId: SegmentAnalyzableExercises,
    public readonly channels: number[],
    public readonly recorderController: SignalRecorderController | null,
    public readonly timer: Timer,
    private resetted: Signal<boolean>,
  ) {
    if (!segmentAnalyzableExercises.includes(exerciseId)) {
      throw new Error(`Exercise id: ${exerciseId} is not valid. You can use: ${segmentAnalyzableExercises}`);
    }
  }

  get segmentsCount() {
    return this.segments.value.length;
  }

  /** current chart duration (without pauses) in milliseconds. */
  get currentDuration() {
    return this.timer.duration * 1000;
  }

  onSegmentStart?: (segmentIndex: number) => void;
  onSegmentCreated?: (segment: EMGSegment) => void;

  start() {
    if (this.recorderController === null) {
      throw new Error('Signal recorder is not initialized');
    }
    if (this.segmentsCount === 0) {
      this.currentSegmentStart = 0;
    } else {
      this.startSegment();
    }
    this.onSegmentStart?.(this.segmentsCount);
  }

  private startSegment() {
    if (this.currentSegmentStart !== null) {
      throw new Error('Segment already started.');
    }
    if (this.recorderController === null) {
      throw new Error('Signal recorder is not initialized');
    }
    this.currentSegmentStart = this.currentDuration;
  }

  end() {
    if (this.currentSegmentStart === null) {
      throw new Error('No active segment to pause.');
    }
    if (this.recorderController === null) {
      throw new Error('Signal recorder is not initialized');
    }

    const dataSource = this.createDataSource(this.currentSegmentStart);

    const name = `${__('exercise.defaultSegmentName')} ${this.segmentsCount + 1}`;

    const newSegment = { start: this.currentSegmentStart, end: this.currentDuration, name, dataSource };

    this.segments.value = [...this.segments.peek(), newSegment];

    this.onSegmentCreated?.(newSegment);

    this.currentSegmentStart = null;
  }

  private createDataSource(start: number, end: number = this.currentDuration) {
    if (this.recorderController === null) {
      throw new Error('Signal recorder is not initialized');
    }
    const id = this.segmentsCount;
    const recording = this.recorderController.retrieveRange({ min: start, max: end });
    return new EMGStaticChartDataSource(id, recording, this.channels);
  }

  setSegmentName(index: number, name: string) {
    if (index < 0 || index >= this.segments.value.length) {
      throw new Error('Invalid segment index.');
    }

    const segment = this.segments.value[index];

    this.segments.value = [
      ...this.segments.peek().slice(0, index),
      { ...segment, name },
      ...this.segments.peek().slice(index + 1),
    ];
  }

  setSegmentDescription(index: number, description: string) {
    if (index < 0 || index >= this.segments.value.length) {
      throw new Error('Invalid segment index.');
    }

    const segment = this.segments.value[index];

    this.segments.value = [
      ...this.segments.peek().slice(0, index),
      { ...segment, description },
      ...this.segments.peek().slice(index + 1),
    ];
  }

  addBaselineSegment(baseline: EMGSegment) {
    const baselineSegment = {
      ...baseline,
      end: baseline.end ?? RELAXATION_MEASUREMENT_TOTAL_TIME,
      name: baseline.name ?? __('exercise.initialSegmentName'),
    } satisfies EMGSegment;

    const newSegments = this.segments.peek().map(segment => {
      return {
        ...segment,
        start: segment.start + baselineSegment.end,
        end: (segment.end ?? 0) + baselineSegment.end,
      };
    });

    this.segments.value = [baselineSegment, ...newSegments];
  }

  reset() {
    this.currentSegmentStart = null;
    this.timer.idle();
    this.recorderController?.reset();
    this.segments.value.length = 0;
    this.resetted.value = true;
  }
}
