import { GeneratedEMGGameExerciseDefinition } from '../../../types/GeneratedExerciseDefinition';

export const getPredefinedStellaBioGameEMGExoRunExercises = (): {
  [key: string]: GeneratedEMGGameExerciseDefinition;
} => ({
  exo_run_emg_view_mvc: {
    calibrationFlow: 'exo-run/stella',
    presentationTemplate: 'time-increment',
    tags: ['exo-run'],
    type: 'emg-game',
    emg: {
      program: {
        minRequiredChannels: 1,
        maxSupportedChannels: 8,
        allowedCableChannelFeatures: ['emg'],
        emgCalibration: true,
        hasGuideline: false,
        steps: [],
      },
    },
    game: {
      id: 'exo_run',
      program: {
        phases: [
          {
            lives: 3,
          },
        ],
        repetitionRandomness: 'no',
      },
      parameters: {
        phases: [
          {
            lives: {
              default: 3,
              unit: 'number',
              values: [2, 3, 4, 5],
              blockAfterStart: true,
            },
          },
        ],
        repetitionRandomness: {
          default: 'yes',
          unit: 'none',
          values: ['yes', 'no'],
          blockAfterStart: true,
        },
      },
    },
  },
});
