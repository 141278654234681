import { parseStyleConfig } from './helpers';

const variants = [
  'heading',
  'heading36',
  'subheading',
  'lightHeading',
  'lightSubheading',
  'subheading48',
  'subheading36',
  'subheading30',
  'subheading60',
] as const;

export type Variants = (typeof variants)[number];

const Heading = parseStyleConfig(variants, {
  variants: {
    heading: () => ({
      fontFamily: 'heading',
      pb: '4',
      color: 'headerTextColor',
      fontSize: ['2xl', null, null, '3xl'],
    }),
    heading36: () => ({
      fontFamily: 'heading',
      fontSize: { base: '2xl', '2xl': '4xl' },
      lineHeight: { base: '2xl', '2xl': '4xl' },
    }),
    subheading: () => ({
      fontFamily: 'subheading',
      py: '2',
      px: '0',
      color: 'headerTextColor',
      fontSize: ['md', null, null, 'xl'],
    }),
    lightHeading: () => ({
      fontFamily: 'heading',
      textAlign: 'center',
      py: '4',
      px: '0',
      color: 'white',
      fontSize: '3xl',
    }),
    lightSubheading: () => ({
      fontFamily: 'subheading',
      py: '2',
      px: '0',
      color: 'white',
      fontWeight: '100',
      fontSize: ['md', null, null, 'xl'],
    }),
    subheading30: () => ({
      fontFamily: 'subheading',
      fontSize: ['xl', null, null, null, null, '2xl', '3xl'],
    }),
    subheading36: () => ({
      fontFamily: 'subheading',
      fontSize: ['2xl', null, null, null, null, '3xl', '4xl'],
    }),
    subheading48: () => ({
      fontFamily: 'subheading',
      fontSize: ['3xl', null, null, null, null, '4xl', '5xl'],
    }),
    subheading60: () => ({
      fontFamily: 'subheading',
      fontSize: ['4xl', null, null, null, null, '5xl', '6xl'],
    }),
  },
});

export default Heading;
