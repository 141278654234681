import { configureStore, PreloadedState } from '@reduxjs/toolkit';
import { deviceApi } from 'queries/device';
import { facilitySpecialistPatientsApi } from 'queries/facilitySpecialistPatients';
import { facilitySpecialistsApi } from 'queries/facilitySpecialists';
import { patientCardsApi } from 'queries/patientCards';
import { trainingReportApi } from 'queries/trainingReport';
import { trainingsApi } from 'queries/trainings';
import emergencyAction from 'slices/emergencyActionSlice';

import calendar from '../slices/calendarSlice';
import chat from '../slices/chatSlice';
import clinicReport from '../slices/clinicReportSlice';
import device from '../slices/deviceSlice';
import exercise from '../slices/exerciseSlice';
import firmware from '../slices/firmwareSlice';
import menu from '../slices/menuSlice';
import modal from '../slices/modalSlice';
import navigation from '../slices/navigationSlice';
import patientList from '../slices/patientListSlice';
import specialistList from '../slices/specialistListSlice';
import specialistPatients from '../slices/specialistPatientsSlice';
import trainingList from '../slices/trainingListSlice';
import trainingReport from '../slices/trainingReportSlice';
import training from '../slices/trainingSlice';
import update from '../slices/updateSlice';
import user from '../slices/userSlice';
import wifi from '../slices/wifiSlice';

import { globalErrorHandlerMiddleware } from './globalErrorHandlerMiddleware';

export type State = ReturnType<typeof store.getState>;
export type AppStore = typeof store;
export type AppDispatch = typeof store.dispatch;

const middlewareConfiguration = { serializableCheck: false };

export const reducer = {
  wifi,
  user,
  specialistPatients,
  specialistList,
  patientList,
  trainingList,
  modal,
  menu,
  exercise,
  chat,
  calendar,
  clinicReport,
  trainingReport,
  training,
  navigation,
  update,
  emergencyAction,
  device,
  firmware,

  [deviceApi.reducerPath]: deviceApi.reducer,
  [facilitySpecialistPatientsApi.reducerPath]: facilitySpecialistPatientsApi.reducer,
  [facilitySpecialistsApi.reducerPath]: facilitySpecialistsApi.reducer,
  [patientCardsApi.reducerPath]: patientCardsApi.reducer,
  [trainingReportApi.reducerPath]: trainingReportApi.reducer,
  [trainingsApi.reducerPath]: trainingsApi.reducer,
};

export const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware(middlewareConfiguration)
      .concat(deviceApi.middleware)
      .concat(facilitySpecialistPatientsApi.middleware)
      .concat(facilitySpecialistsApi.middleware)
      .concat(patientCardsApi.middleware)
      .concat(trainingReportApi.middleware)
      .concat(trainingsApi.middleware)
      .concat(globalErrorHandlerMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
});

export const setupStore = (preloadedState?: PreloadedState<State>) => {
  return configureStore({
    reducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware(middlewareConfiguration)
        .concat(deviceApi.middleware)
        .concat(facilitySpecialistPatientsApi.middleware)
        .concat(facilitySpecialistsApi.middleware)
        .concat(patientCardsApi.middleware)
        .concat(trainingReportApi.middleware)
        .concat(trainingsApi.middleware)
        .concat(globalErrorHandlerMiddleware),
    preloadedState,
  });
};
