import { memo, useRef } from 'react';
import { useSignalEffect, useSignals } from '@preact/signals-react/runtime';
import { Timers } from 'config/timers';
import { ReadonlySignal } from 'helpers/signal';

interface Props {
  xOffset: number;
  yOffset: number;
  yHeight: number;
  level: ReadonlySignal<number>;
  color: string;
  width?: number;
  label?: string;
}

export const SVGBackgroundBar = memo(({ xOffset, yOffset, yHeight, level, color, width = 115, label }: Props) => {
  useSignals();
  const rectRef = useRef<SVGRectElement>(null);

  useSignalEffect(() => {
    const currentHeight = yHeight * (level.value / 100);
    const currentYoffset = yOffset + (yHeight - currentHeight);

    if (!rectRef.current) {
      return;
    }

    rectRef.current.setAttribute('height', currentHeight.toString());
    rectRef.current.setAttribute('y', currentYoffset.toString());
  });

  return (
    <rect
      ref={rectRef}
      x={xOffset}
      width={width}
      fill={color}
      style={{
        transition: `y ${Timers.EXERCISE_DATA_REFRESH_INTERVAL}ms linear, height ${Timers.EXERCISE_DATA_REFRESH_INTERVAL}ms linear`,
      }}
      data-testid={label + (label ? '-rect' : 'rect')}
    />
  );
});

SVGBackgroundBar.displayName = 'SVGBackgroundBar';
