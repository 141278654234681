export type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

/**
 * Checks if two object are equal by comparing each property of two same type objects.
 */
export function objectEquals<T extends object>(a: T, b: T) {
  for (const prop in a) {
    if (a[prop] !== b[prop]) {
      return false;
    }
  }

  for (const prop in b) {
    if (a[prop] !== b[prop]) {
      return false;
    }
  }

  return true;
}

export function findKeyInObject<T extends object>(
  obj: T,
  predicate: (key: string, value: Required<T>[keyof T]) => boolean,
): keyof T | null {
  const props = Object.entries(obj).filter(kv => predicate(kv[0], kv[1]));

  if (props.length === 0) {
    return null;
  }

  return props[0][0] as keyof T;
}

export function deepPickByTruthyValue<T extends Record<string, any>>(obj: T) {
  const result: Partial<T> = {};

  function pickRecursive(source: Record<string, any>, target: Record<string, any>) {
    for (const key in source) {
      if (key in source) {
        const value = source[key];
        if (value !== undefined) {
          if (typeof value === 'object' && !Array.isArray(value)) {
            // If the value is an object and not an array, recurse into it.
            target[key] = {};
            pickRecursive(value, target[key]);
            if (Object.keys(target[key]).length === 0) {
              // Remove empty objects after recursion.
              delete target[key];
            }
          } else if (value) {
            // If the value is truthy, add it to the result.
            target[key] = value;
          }
        }
      }
    }
  }

  pickRecursive(obj, result);
  return result;
}

export function entriesFromObject<T extends object>(object: T): Entries<T> {
  return Object.entries(object) as Entries<T>;
}

export function isNil(value: unknown): value is null | undefined {
  return value === null || value === undefined;
}
