import { GeneratedEMSExerciseDefinition } from '../../types/GeneratedExerciseDefinition';

const emsEmgExerciseIds = [
  'emgt_arm_extension_support',
  'emgt_emg_triggered_1ch',
  'emgt_fecal_incontinence',
  'emgt_grasp_and_release',
  'emgt_hand_to_mouth',
  'emgt_incontinence',
  'emgt_open_and_close',
] as const;

export type EMSEMGExerciseId = (typeof emsEmgExerciseIds)[number];

export const getPredefinedStellaBioEMSEMGExercise = (): {
  [key in EMSEMGExerciseId]: GeneratedEMSExerciseDefinition;
} => ({
  emgt_grasp_and_release: {
    calibrationFlow: 'ems-emg-grasp-and-release/stella',
    presentationTemplate: 'repetitions-labelled-time',
    type: 'ems-emg',
    tags: ['emg', 'ems', 'trigger', 'fes'],
    ems: {
      program: {
        defaultChannelValues: [
          {
            channelIndex: 0,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 22000000,
            plateauTime: 10000000,
            pauseTime: 10000000,
          },
          {
            channelIndex: 1,
            fallTime: 1000000,
            delay: 3000000,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 22000000,
            plateauTime: 7000000,
            pauseTime: 10000000,
          },
          {
            channelIndex: 2,
            fallTime: 1000000,
            delay: 6000000,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 22000000,
            plateauTime: 4000000,
            pauseTime: 10000000,
          },
        ],
        maxIntensity: [0.1, 0.1, 0.1],
        phases: [
          {
            needsTrigger: true,
            channels: [],
          },
        ],
        phasesRepetition: 10,
        stimCalibration: [
          {
            channelIndex: 0,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 22000000,
            plateauTime: 10000000,
            pauseTime: 10000000,
          },
          {
            channelIndex: 1,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 19000000,
            plateauTime: 7000000,
            pauseTime: 10000000,
          },
          {
            channelIndex: 2,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 16000000,
            plateauTime: 4000000,
            pauseTime: 10000000,
          },
        ],
        programTime: 220000000,
        minRequiredChannels: 3,
        maxSupportedChannels: 3,
        allowedCableChannelFeatures: ['ems'],
        tags: ['emg', 'ems', 'trigger'],
      },
      parameters: {
        phases: [
          {
            pulseWidth: {
              unit: 'us',
              default: 250,
              values: [50, 100, 150, 200, 250, 300, 350, 400, 450, 500],
              blockAfterStart: true,
            },
          },
        ],
        phaseRepetition: {
          unit: 'number',
          default: 10,
          values: [5, 10, 15, 20, 25, 30],
          blockAfterStart: true,
        },
      },
    },
  },
  emgt_open_and_close: {
    calibrationFlow: 'ems-emg-open-and-close/stella',
    presentationTemplate: 'repetitions-labelled-time',
    type: 'ems-emg',
    tags: ['emg', 'ems', 'trigger', 'fes'],
    ems: {
      program: {
        defaultChannelValues: [
          {
            channelIndex: 0,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 28000000,
            plateauTime: 6000000,
            pauseTime: 20000000,
          },
          {
            channelIndex: 1,
            fallTime: 1000000,
            delay: 8000000,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 28000000,
            plateauTime: 8000000,
            pauseTime: 10000000,
          },
          {
            channelIndex: 2,
            fallTime: 1000000,
            delay: 10000000,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 28000000,
            plateauTime: 6000000,
            pauseTime: 10000000,
          },
        ],
        maxIntensity: [0.1, 0.1, 0.1],
        phases: [
          {
            needsTrigger: true,
            channels: [],
          },
        ],
        phasesRepetition: 10,
        stimCalibration: [
          {
            channelIndex: 0,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 28000000,
            plateauTime: 6000000,
            pauseTime: 20000000,
          },
          {
            channelIndex: 1,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 20000000,
            plateauTime: 8000000,
            pauseTime: 10000000,
          },
          {
            channelIndex: 2,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 18000000,
            plateauTime: 6000000,
            pauseTime: 10000000,
          },
        ],
        programTime: 280000000,
        minRequiredChannels: 3,
        maxSupportedChannels: 3,
        tags: ['emg', 'ems', 'trigger', 'fes'],
        allowedCableChannelFeatures: ['ems'],
      },
      parameters: {
        phases: [
          {
            pulseWidth: {
              unit: 'us',
              default: 250,
              values: [50, 100, 150, 200, 250, 300, 350, 400, 450, 500],
              blockAfterStart: true,
            },
          },
        ],
        phaseRepetition: {
          unit: 'number',
          default: 10,
          values: [5, 10, 15, 20, 25, 30],
          blockAfterStart: true,
        },
      },
    },
  },
  emgt_arm_extension_support: {
    calibrationFlow: 'ems-emg-arm-extension/stella',
    presentationTemplate: 'repetitions-labelled-time',
    type: 'ems-emg',
    tags: ['emg', 'ems', 'trigger', 'fes'],
    ems: {
      program: {
        defaultChannelValues: [
          {
            channelIndex: 0,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 18000000,
            plateauTime: 7000000,
            pauseTime: 9000000,
          },
          {
            channelIndex: 1,
            fallTime: 1000000,
            delay: 1000000,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 18000000,
            plateauTime: 5000000,
            pauseTime: 10000000,
          },
          {
            channelIndex: 2,
            fallTime: 1000000,
            delay: 2000000,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 18000000,
            plateauTime: 4000000,
            pauseTime: 10000000,
          },
        ],
        maxIntensity: [0.1, 0.1, 0.1],
        phases: [
          {
            needsTrigger: true,
            channels: [],
          },
        ],
        phasesRepetition: 10,
        stimCalibration: [
          {
            channelIndex: 0,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 18000000,
            plateauTime: 7000000,
            pauseTime: 9000000,
          },
          {
            channelIndex: 1,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 17000000,
            plateauTime: 5000000,
            pauseTime: 10000000,
          },
          {
            channelIndex: 2,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 16000000,
            plateauTime: 4000000,
            pauseTime: 10000000,
          },
        ],
        programTime: 180000000,
        minRequiredChannels: 3,
        maxSupportedChannels: 3,
        tags: ['emg', 'ems', 'trigger'],
        allowedCableChannelFeatures: ['ems'],
      },
      parameters: {
        phases: [
          {
            pulseWidth: {
              unit: 'us',
              default: 250,
              values: [50, 100, 150, 200, 250, 300, 350, 400, 450, 500],
              blockAfterStart: true,
            },
          },
        ],
        phaseRepetition: {
          unit: 'number',
          default: 10,
          values: [5, 10, 15, 20, 25, 30],
          blockAfterStart: true,
        },
      },
    },
  },
  emgt_hand_to_mouth: {
    calibrationFlow: 'ems-emg-hand-to-mouth/stella',
    presentationTemplate: 'repetitions-labelled-time',
    type: 'ems-emg',
    tags: ['emg', 'ems', 'trigger', 'fes'],
    ems: {
      program: {
        defaultChannelValues: [
          {
            channelIndex: 0,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 18000000,
            plateauTime: 7000000,
            pauseTime: 9000000,
          },
          {
            channelIndex: 1,
            fallTime: 1000000,
            delay: 1000000,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 18000000,
            plateauTime: 5000000,
            pauseTime: 10000000,
          },
          {
            channelIndex: 2,
            fallTime: 1000000,
            delay: 1000000,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 18000000,
            plateauTime: 5000000,
            pauseTime: 10000000,
          },
          {
            channelIndex: 3,
            fallTime: 1000000,
            delay: 1000000,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 18000000,
            plateauTime: 5000000,
            pauseTime: 10000000,
          },
        ],
        maxIntensity: [0.1, 0.1, 0.1, 0.1],
        phases: [
          {
            needsTrigger: true,
            channels: [],
          },
        ],
        phasesRepetition: 10,
        stimCalibration: [
          {
            channelIndex: 0,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 18000000,
            plateauTime: 7000000,
            pauseTime: 9000000,
          },
          {
            channelIndex: 1,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 17000000,
            plateauTime: 5000000,
            pauseTime: 10000000,
          },
          {
            channelIndex: 2,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 17000000,
            plateauTime: 5000000,
            pauseTime: 10000000,
          },
          {
            channelIndex: 3,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 17000000,
            plateauTime: 5000000,
            pauseTime: 10000000,
          },
        ],
        programTime: 180000000,
        minRequiredChannels: 4,
        maxSupportedChannels: 4,
        tags: ['emg', 'ems', 'trigger'],
        allowedCableChannelFeatures: ['ems'],
      },
      parameters: {
        phases: [
          {
            pulseWidth: {
              unit: 'us',
              default: 250,
              values: [50, 100, 150, 200, 250, 300, 350, 400, 450, 500],
              blockAfterStart: true,
            },
          },
        ],
        phaseRepetition: {
          unit: 'number',
          default: 10,
          values: [5, 10, 15, 20, 25, 30],
          blockAfterStart: true,
        },
      },
    },
  },
  emgt_emg_triggered_1ch: {
    calibrationFlow: 'ems-emg/stella',
    presentationTemplate: 'repetitions-labelled-time',
    type: 'ems-emg',
    tags: ['emg', 'ems', 'trigger'],
    ems: {
      program: {
        defaultChannelValues: [
          {
            channelIndex: 0,
            fallTime: 500000,
            delay: 0,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 300,
            riseTime: 500000,
            runTime: 10000000,
            plateauTime: 3000000,
            pauseTime: 6000000,
          },
        ],
        maxIntensity: [0.1],
        phases: [
          {
            needsTrigger: true,
            channels: [],
          },
        ],
        phasesRepetition: 10,
        stimCalibration: [
          {
            channelIndex: 0,
            fallTime: 500000,
            delay: 0,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 300,
            riseTime: 500000,
            runTime: 10000000,
            plateauTime: 3000000,
            pauseTime: 6000000,
          },
        ],
        programTime: 100000000,
        minRequiredChannels: 1,
        maxSupportedChannels: 8,
        tags: ['emg', 'ems', 'trigger'],
        allowedCableChannelFeatures: ['ems'],
      },
      parameters: {
        phases: [
          {
            pulseWidth: {
              unit: 'us',
              default: 250,
              values: [50, 100, 150, 200, 250, 300, 350, 400, 450, 500],
              blockAfterStart: true,
            },
          },
        ],
        phaseRepetition: {
          unit: 'number',
          default: 10,
          values: [5, 10, 15, 20, 25, 30],
          blockAfterStart: true,
        },
      },
    },
  },
  emgt_fecal_incontinence: {
    calibrationFlow: 'ems-emg/stella',
    presentationTemplate: 'repetitions-labelled-time',
    type: 'ems-emg',
    tags: ['emg', 'ems', 'trigger'],
    ems: {
      program: {
        defaultChannelValues: [
          {
            channelIndex: 0,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 12000000,
            plateauTime: 5000000,
            pauseTime: 5000000,
          },
        ],
        maxIntensity: [0.1],
        phases: [
          {
            needsTrigger: true,
            channels: [],
          },
        ],
        phasesRepetition: 10,
        stimCalibration: [
          {
            channelIndex: 0,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 12000000,
            plateauTime: 5000000,
            pauseTime: 5000000,
          },
        ],
        programTime: 120000000,
        minRequiredChannels: 1,
        maxSupportedChannels: 2,
        tags: ['emg', 'ems', 'trigger', 'pelvic'],
        allowedCableChannelFeatures: ['ems-pelvic'],
        requiredCableChannelFeatures: ['ems-pelvic'],
      },
      parameters: {
        phases: [
          {
            pulseWidth: {
              unit: 'us',
              default: 250,
              values: [50, 100, 150, 200, 250, 300, 350, 400, 450, 500],
              blockAfterStart: true,
            },
          },
        ],
        phaseRepetition: {
          unit: 'number',
          default: 10,
          values: [5, 10, 15, 20, 25, 30],
          blockAfterStart: true,
        },
      },
    },
  },
  emgt_incontinence: {
    calibrationFlow: 'ems-emg/stella',
    presentationTemplate: 'repetitions-labelled-time',
    type: 'ems-emg',
    tags: ['emg', 'ems', 'trigger'],
    ems: {
      program: {
        defaultChannelValues: [
          {
            channelIndex: 0,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 12000000,
            plateauTime: 4000000,
            pauseTime: 6000000,
          },
        ],
        maxIntensity: [0.1],
        phases: [
          {
            needsTrigger: true,
            channels: [],
          },
        ],
        phasesRepetition: 10,
        stimCalibration: [
          {
            channelIndex: 0,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 12000000,
            plateauTime: 4000000,
            pauseTime: 6000000,
          },
        ],
        programTime: 120000000,
        minRequiredChannels: 1,
        maxSupportedChannels: 2,
        tags: ['emg', 'ems', 'trigger', 'pelvic'],
        allowedCableChannelFeatures: ['ems-pelvic'],
        requiredCableChannelFeatures: ['ems-pelvic'],
      },
      parameters: {
        phases: [
          {
            pulseWidth: {
              unit: 'us',
              default: 250,
              values: [50, 100, 150, 200, 250, 300, 350, 400, 450, 500],
              blockAfterStart: true,
            },
          },
        ],
        phaseRepetition: {
          unit: 'number',
          default: 10,
          values: [5, 10, 15, 20, 25, 30],
          blockAfterStart: true,
        },
      },
    },
  },
});
