import { To } from 'react-router-dom';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiFetch, apiJsonFetch } from 'config/api';
import { State } from 'config/store';
import { handleError } from 'helpers/handleError';
import { logger } from 'helpers/logger';
import { withBasicErrorHandling } from 'helpers/withBasicErrorHandling';
import { exoClinicApi } from 'services/ExoClinicBackendApi';
import { ExoClinicBackendOpenApiSchemas } from 'services/ExoClinicBackendOpenApi';
import { TrainingCreateDTO, TrainingResponseDTO, TrainingUpdateDTO } from 'types';

import { ExerciseReportData, TrainingDataDTO } from './trainingReportSlice';

export interface TrainingState {
  /**
   * Currently chosen template for training. Initialized with {@link initializeTrainingFromTemplate}.
   */
  currentTemplate: ExoClinicBackendOpenApiSchemas['TrainingTemplateResponseDto'] | null;
  /**
   * Currently performed training. Should be prepared using {@link prepareNewTrainingForPatient} and then started with {@link startTraining}.
   * If preparing fails or {@link finishTraining} is used then current training is set to null.
   */
  current: TrainingResponseDTO | null;
  /**
   * Current status of the training. `error` status occurs when a training could not be prepared properly using {@link prepareNewTrainingForPatient}.
   */
  status: 'none' | 'initialized' | 'prepared' | 'started' | 'finished' | 'error';
  /**
   * Current exercise index.
   */
  currentExercise: number;
  /**
   * Number of exercises in a training
   */
  exerciseCount: number;
  /**
   * If training is repeated, then this flag is set to true
   */
  isRepeated: boolean;
  /**
   * If training is repeated, then this holds data about a training that was repeated.
   */
  repeatedTraining: ExoClinicBackendOpenApiSchemas['TrainingResponseDto'] | null;
  /**
   * Upload status of training to database.
   */
  uploadStatus: 'started' | 'finished' | null;
  /**
   * Specifies where to navigate when exercise ends in an error state.
   */
  afterErrorNavigateTo: To | number | null;
}

const initialState: TrainingState = {
  currentTemplate: null,
  current: null,
  status: 'none',
  currentExercise: 0,
  exerciseCount: 0,
  isRepeated: false,
  repeatedTraining: null,
  uploadStatus: null,
  afterErrorNavigateTo: null,
};

export const prepareNewTrainingForPatient = createAsyncThunk(
  'training/prepare-from-template',
  (patientId: string, { getState }) => {
    return withBasicErrorHandling(
      async () => {
        const {
          training: { status, currentTemplate },
        } = getState() as State;

        if (!currentTemplate) {
          throw new Error('Cannot prepare training without initialization.');
        }

        if (status !== 'initialized') {
          throw new Error(
            'Cannot prepare a training if current training is already prepared or last training was never finished',
          );
        }

        const response = await apiJsonFetch<TrainingResponseDTO, TrainingCreateDTO>(
          '/training',
          {
            comment: '',
            dateTime: new Date(Date.now() + 30000),
            trainingTemplateId: currentTemplate.id,
            userProfileId: patientId,
          },
          { method: 'POST' },
        );

        return await response.json();
      },
      (err: any) => handleError(err.response),
    );
  },
);

export const refreshTraining = createAsyncThunk('training/refresh', (_, { getState }) => {
  return withBasicErrorHandling(
    async () => {
      const {
        training: { current, status },
      } = getState() as State;

      if (!current) {
        throw new Error('Cannot refresh training without preapring a training.');
      }

      if (status === 'initialized' || status === 'error') {
        throw new Error('Cannot refresh a training that was never prepared or there in an error.');
      }

      const response = await apiFetch<TrainingResponseDTO>('/training/' + current.id);

      return await response.json();
    },
    (err: any) => handleError(err.response),
  );
});

export const startTraining = createAsyncThunk('training/start', (_, { getState, dispatch }) => {
  return withBasicErrorHandling(
    async () => {
      const {
        training: { current, status },
      } = getState() as State;

      if (!current) {
        throw new Error('Cannot start training without preparing a training.');
      }

      if (status !== 'prepared') {
        throw new Error('Training is already started or was never prepared.');
      }

      await exoClinicApi.trainings.training(current.id).start();

      dispatch(refreshTraining);
    },
    (err: any) => handleError(err.response),
  );
});

export const repeatTraining = createAsyncThunk(
  'training/repeat',
  ({ patientId, trainingId }: { patientId: string; trainingId: string }, { getState, dispatch }) => {
    return withBasicErrorHandling(
      async () => {
        const {
          trainingList: { currentFilters },
        } = getState() as State;

        logger.info('trainingSlice.repeatTraining', `Repeating training '${trainingId}'.`);
        const training = await exoClinicApi.trainings.training(trainingId).get();

        //TODO: in future we can extend api to handle retrieving only one template based on id
        const trainingTemplates = (await exoClinicApi.trainingTemplates.all(currentFilters)).content;

        logger.info(
          'trainingSlice.repeatTraining',
          `Training has stored data for ${training.trainingData.length} exercise(s). Loading training template '${training.trainingTemplate.name}'.`,
        );
        const template = trainingTemplates.find(t => t.name === training.trainingTemplate.name);

        if (!template) {
          throw new Error(
            `Cannot repeat training. Cannot find template for '${training.trainingTemplate.name}' in training with id '${training.id}'`,
          );
        }

        logger.info(
          'trainingSlice.repeatTraining',
          `Initializing training from template '${template.name}'. Template has ${
            Object.keys(template.exercises).length
          } exercise(s).`,
        );
        dispatch(initializeTrainingFromTemplate(template));

        logger.info('trainingSlice.repeatTraining', `Preparing training for patient '${patientId}'.`);
        await dispatch(prepareNewTrainingForPatient(patientId));

        const {
          training: { current },
        } = getState() as State;

        if (!current) {
          throw new Error('Cannot repeat training without preparing a training.');
        }

        logger.info('trainingSlice.repeatTraining', `Starting new training '${current.id}'.`);
        await exoClinicApi.trainings.training(current.id).start();

        dispatch(refreshTraining);

        return training;
      },
      (err: any) => handleError(err.response),
    );
  },
);

export const finishTraining = createAsyncThunk('training/finish', (_, { getState, dispatch }) => {
  return withBasicErrorHandling(
    async () => {
      const {
        training: { current, status },
      } = getState() as State;

      if (!current) {
        throw new Error('Cannot finish training without preapring a training.');
      }

      if (status !== 'started') {
        throw new Error('Cannot finish training if training was never started.');
      }

      await exoClinicApi.trainings.training(current.id).finish();

      dispatch(refreshTraining);
    },
    (err: any) => handleError(err.response),
  );
});

export const updateTraining = createAsyncThunk('training/update', (data: ExerciseReportData, { getState }) => {
  return withBasicErrorHandling(
    async () => {
      const {
        training: { current, status, currentTemplate },
      } = getState() as State;

      if (!current) {
        throw new Error('Cannot start training without preapring a training.');
      }

      if (!currentTemplate) {
        throw new Error('Cannot start training without initializing a training with a template.');
      }

      if (status !== 'started') {
        throw new Error('Cannot update training that was never started.');
      }

      const response = await apiFetch<TrainingResponseDTO>('/training/' + current.id, {
        method: 'PATCH',
        body: JSON.stringify({
          comment: '',
          trainingData: {
            exercise: data,
          } as TrainingDataDTO,
        } as TrainingUpdateDTO),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      return await response.json();
    },
    (err: any) => handleError(err.response),
  );
});

const exerciseSlice = createSlice({
  name: 'exercise',
  initialState,
  reducers: {
    // TODO: add initialization and training preparation from existing planned training
    initializeTrainingFromTemplate: (
      state,
      { payload }: PayloadAction<ExoClinicBackendOpenApiSchemas['TrainingTemplateResponseDto']>,
    ) => {
      state.currentTemplate = payload;
      state.current = null;
      state.status = 'initialized';
      state.currentExercise = 0;
      state.exerciseCount = Object.keys(payload.exercises).length;
      state.isRepeated = false;
      state.repeatedTraining = null;
      state.uploadStatus = null;
      state.afterErrorNavigateTo = null;
    },
    moveToNextExerciseInTraining: state => {
      state.currentExercise += 1;
    },
    abortTraining: state => {
      state.currentTemplate = null;
      state.current = null;
      state.status = state.status === 'finished' ? 'initialized' : 'error';
      state.currentExercise = 0;
      state.isRepeated = false;
      state.repeatedTraining = null;
      state.uploadStatus = null;
      state.afterErrorNavigateTo = null;
    },
    startTrainingUpload: state => {
      state.uploadStatus = 'started';
    },
    markTrainingAsUploaded: state => {
      state.uploadStatus = 'finished';
    },
    setErrorNavigationForTraining: (state, { payload }: PayloadAction<To | number>) => {
      state.afterErrorNavigateTo = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(prepareNewTrainingForPatient.fulfilled, (state, { payload }) => {
      state.current = payload;
      state.status = 'prepared';
    });
    builder.addCase(prepareNewTrainingForPatient.pending, state => {
      state.current = null;
    });
    builder.addCase(prepareNewTrainingForPatient.rejected, state => {
      state.current = null;
      state.status = 'error';
    });
    builder.addCase(startTraining.fulfilled, state => {
      state.status = 'started';
    });
    builder.addCase(repeatTraining.fulfilled, (state, { payload }) => {
      state.status = 'started';
      state.isRepeated = true;
      state.repeatedTraining = payload;
    });
    builder.addCase(refreshTraining.fulfilled, (state, { payload }) => {
      state.current = payload;
    });
    builder.addCase(updateTraining.fulfilled, (state, { payload }) => {
      state.current = payload;
    });
    builder.addCase(finishTraining.fulfilled, state => {
      state.current = null;
      state.status = 'finished';
      state.isRepeated = false;
      state.repeatedTraining = null;
    });
  },
});

export const {
  initializeTrainingFromTemplate,
  moveToNextExerciseInTraining,
  abortTraining,
  setErrorNavigationForTraining,
  startTrainingUpload,
  markTrainingAsUploaded,
} = exerciseSlice.actions;

export default exerciseSlice.reducer;
