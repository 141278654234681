import { calculateSensitivity } from 'helpers/units';
import { GeneratedCAMGameExerciseDefinition } from 'libs/exo-session-manager/core/types/GeneratedExerciseDefinition';

export const getPredefinedMeissaOTGameExoRunExercises = (): {
  [key: string]: GeneratedCAMGameExerciseDefinition;
} => ({
  game_exo_run_cam_classic_meissa: {
    type: 'cam-game',
    calibrationFlow: 'cam-isokinetic/meissa/exo-run',
    presentationTemplate: 'time',
    tags: ['exo-run'],
    cam: {
      main: {
        program: {
          phases: [
            {
              time: 10 * 60,
              maxSpeed: 90,
              activeMovementDirection: 'both',
              coupling: {
                force: {
                  extension: {
                    sensitivity: calculateSensitivity(1, 1000),
                    deadband: 2,
                  },
                  torque: {
                    sensitivity: calculateSensitivity(1, 1000),
                    deadband: 2,
                  },
                },
              },
            },
          ],
        },
        parameters: {
          phases: [
            {
              activeMovementDirection: {
                default: 'both',
                unit: 'none',
                values: ['both'],
                blockAfterStart: true,
              },
              forceSource: {
                default: 'torque',
                unit: 'none',
                values: ['torque'],
                blockAfterStart: true,
              },
              time: {
                default: 10 * 60,
                unit: 's',
                values: [60, 2 * 60, 5 * 60, 10 * 60, 15 * 60, 20 * 60, 25 * 60, 30 * 60, 45 * 60, 60 * 60],
                blockAfterStart: false,
              },
              maxSpeed: {
                default: 90,
                unit: 'deg/s',
                values: [5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120],
                blockAfterStart: false,
              },
            },
          ],
        },
      },
    },
    game: {
      id: 'exo_run',
      program: {
        phases: [
          {
            lives: 3,
            gameSteeringMode: 'normalControl',
          },
        ],
        repetitionRandomness: 'no',
      },
      parameters: {
        phases: [
          {
            lives: {
              default: 3,
              unit: 'number',
              values: [2, 3, 4, 5],
              blockAfterStart: true,
            },
            gameSteeringMode: {
              default: 'normalControl',
              unit: 'none',
              values: ['normalControl', 'invertControl'],
              blockAfterStart: false,
            },
          },
        ],
        repetitionRandomness: {
          default: 'yes',
          unit: 'none',
          values: ['yes', 'no'],
          blockAfterStart: true,
        },
      },
    },
  },
});
