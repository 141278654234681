import { cpmMeissaFlow } from 'libs/exo-session-manager/core/calibrations/cpmFlow';
import { setParametersFlow } from 'libs/exo-session-manager/core/calibrations/setParametersFlow';
import { CalibrationFlowDefinition } from 'libs/exo-session-manager/core/calibrations/types';

import { camMeissaFlow } from './camFlows';
import { connectCableFlow } from './connectCableFlow';
import { emgFlow } from './emgFlow';
import { emsFlow } from './emsFlow';
import { forceFlow } from './forceFlow';
import { gameFlow } from './gameFlow';
import { sideSelectionFlow } from './meissaBasingSideSelectionFlow';
import { moveToCenterMeissaFlow as moveToCenterBeforeGameMeissaFlow } from './moveToCenterFlow';
import { prepareChannelsAndConnectElectrodesFlow } from './prepareChannelsAndConnectElectrodesFlow';
export const meissaOTCalibrationFlow = {
  'cpm/meissa': {
    initial: 'basing-settings',
    alerts: {
      deviceDisconnected: {
        type: 'error',
        condition: 'isDeviceDisconnected',
      },
      extensionDetached: {
        type: 'warning',
        condition: '!isCorrectExtensionAttached',
        resolveState: 'attach-extension',
      },
    },
    stepsNumber: 5,
    states: {
      ...setParametersFlow(null, 'meissa-basing-side-selection', 1),
      ...sideSelectionFlow('basing-settings', 'meissa-basing-set-correct-position', 2),
      ...cpmMeissaFlow('meissa-basing-side-selection', null, 3),
    },
  },
  'cpm-emg/meissa': {
    initial: 'basing-settings',
    alerts: {
      cableDetached: {
        type: 'warning',
        condition: 'isCableDetached',
        resolveState: 'connect-cable',
      },
      extensionDetached: {
        type: 'warning',
        condition: '!isCorrectExtensionAttached',
        resolveState: 'attach-extension',
      },
      disconnectedElectrode: {
        type: 'warning',
        condition: 'areElectrodesDisconnected',
        resolveState: 'connect-electrodes',
      },
      deviceDisconnected: {
        type: 'error',
        condition: 'isDeviceDisconnected',
      },
    },
    stepsNumber: 9,
    states: {
      ...setParametersFlow(null, 'meissa-basing-side-selection', 1),
      ...sideSelectionFlow('basing-settings', 'meissa-basing-set-correct-position', 2),
      ...cpmMeissaFlow('meissa-basing-side-selection', 'connect-cable', 4),
      ...connectCableFlow('meissa-spasticism-armrest', 'channel-muscle-selector', 8),
      ...prepareChannelsAndConnectElectrodesFlow('connect-cable', 'emg-calibration', 9),
      ...emgFlow('connect-electrodes', null, 12),
    },
  },
  'cpm-force/meissa': {
    initial: 'basing-settings',
    alerts: {
      extensionDetached: {
        type: 'warning',
        condition: '!isCorrectExtensionAttached',
        resolveState: 'attach-extension',
      },
      deviceDisconnected: {
        type: 'error',
        condition: 'isDeviceDisconnected',
      },
    },
    stepsNumber: 6,
    states: {
      ...setParametersFlow(null, 'meissa-basing-side-selection', 1),
      ...sideSelectionFlow('basing-settings', 'meissa-basing-set-correct-position', 2),
      ...cpmMeissaFlow('meissa-basing-side-selection', 'force-calibration', 4),
      ...forceFlow('meissa-basing-range-of-movement', null, 6),
    },
  },
  'cpm-ems/meissa': {
    initial: 'basing-settings',
    alerts: {
      cableDetached: {
        type: 'warning',
        condition: 'isCableDetached',
        resolveState: 'connect-cable',
      },
      extensionDetached: {
        type: 'warning',
        condition: '!isCorrectExtensionAttached',
        resolveState: 'attach-extension',
      },
      disconnectedElectrode: {
        type: 'warning',
        condition: 'areElectrodesDisconnected',
        resolveState: 'connect-electrodes',
      },
      deviceDisconnected: {
        type: 'error',
        condition: 'isDeviceDisconnected',
      },
    },
    stepsNumber: 11,
    states: {
      ...setParametersFlow(null, 'meissa-basing-side-selection', 1),
      ...sideSelectionFlow('basing-settings', 'meissa-basing-set-correct-position', 2),
      ...cpmMeissaFlow('meissa-basing-side-selection', 'connect-cable', 4),
      ...connectCableFlow('meissa-spasticism-armrest', 'channel-muscle-selector', 8),
      ...prepareChannelsAndConnectElectrodesFlow('connect-cable', 'ems-warnings', 9),
      ...emsFlow('connect-electrodes', null, 12),
    },
  },
  'cam-isokinetic/meissa': {
    initial: 'basing-settings',
    alerts: {
      deviceDisconnected: {
        type: 'error',
        condition: 'isDeviceDisconnected',
      },
      extensionDetached: {
        type: 'warning',
        condition: '!isCorrectExtensionAttached',
        resolveState: 'attach-extension',
      },
    },
    stepsNumber: 7,
    states: {
      ...setParametersFlow(null, 'meissa-basing-side-selection', 1),
      ...sideSelectionFlow('basing-settings', 'meissa-basing-set-correct-position', 2),
      ...camMeissaFlow('meissa-basing-side-selection', 'force-calibration', 4),
      ...forceFlow('meissa-basing-range-of-movement', null, 6),
    },
  },
  'cam-isokinetic/meissa/cosmic-mission': {
    initial: 'game-select-control',
    alerts: {
      deviceDisconnected: {
        type: 'error',
        condition: 'isDeviceDisconnected',
      },
      extensionDetached: {
        type: 'warning',
        condition: '!isCorrectExtensionAttached',
        resolveState: 'attach-extension',
      },
    },
    stepsNumber: 8,
    states: {
      ...gameFlow(null, 'basing-settings', 1),
      ...setParametersFlow('game-select-level', 'meissa-basing-side-selection', 3),
      ...sideSelectionFlow('basing-settings', 'meissa-basing-set-correct-position', 4),
      ...camMeissaFlow('meissa-basing-side-selection', 'force-calibration', 6),
      ...forceFlow('meissa-basing-range-of-movement', null, 8),
    },
  },
  'cam-isokinetic/meissa/driving-game': {
    initial: 'game-select-control',
    alerts: {
      deviceDisconnected: {
        type: 'error',
        condition: 'isDeviceDisconnected',
      },
      extensionDetached: {
        type: 'warning',
        condition: '!isCorrectExtensionAttached',
        resolveState: 'attach-extension',
      },
    },
    stepsNumber: 9,
    states: {
      ...gameFlow(null, 'basing-settings', 1),
      ...setParametersFlow('game-select-level', 'meissa-basing-side-selection', 3),
      ...sideSelectionFlow('basing-settings', 'meissa-basing-set-correct-position', 4),
      ...camMeissaFlow('meissa-basing-side-selection', 'force-calibration', 6),
      ...forceFlow('meissa-basing-range-of-movement', 'meissa-move-into-center-before-game', 8),
      ...moveToCenterBeforeGameMeissaFlow('force-calibration', null, 9),
    },
  },
  'cam-isokinetic/meissa/exo-run': {
    initial: 'game-select-control',
    alerts: {
      deviceDisconnected: {
        type: 'error',
        condition: 'isDeviceDisconnected',
      },
      extensionDetached: {
        type: 'warning',
        condition: '!isCorrectExtensionAttached',
        resolveState: 'attach-extension',
      },
    },
    stepsNumber: 9,
    states: {
      ...gameFlow(null, 'basing-settings', 1),
      ...setParametersFlow('game-select-level', 'meissa-basing-side-selection', 3),
      ...sideSelectionFlow('basing-settings', 'meissa-basing-set-correct-position', 4),
      ...camMeissaFlow('meissa-basing-side-selection', 'force-calibration', 6),
      ...forceFlow('meissa-basing-range-of-movement', 'meissa-move-into-center-before-game', 8),
      ...moveToCenterBeforeGameMeissaFlow('force-calibration', null, 9),
    },
  },
  'cam-torque/meissa': {
    initial: 'basing-settings',
    alerts: {
      deviceDisconnected: {
        type: 'error',
        condition: 'isDeviceDisconnected',
      },
      extensionDetached: {
        type: 'warning',
        condition: '!isCorrectExtensionAttached',
        resolveState: 'attach-extension',
      },
    },
    stepsNumber: 7,
    states: {
      ...setParametersFlow(null, 'meissa-basing-side-selection', 1),
      ...sideSelectionFlow('basing-settings', 'meissa-basing-set-correct-position', 2),
      ...camMeissaFlow('meissa-basing-side-selection', 'force-calibration', 4),
      ...forceFlow('meissa-basing-range-of-movement', null, 6),
    },
  },
  'cam-turn-key/meissa': {
    initial: 'basing-settings',
    alerts: {
      deviceDisconnected: {
        type: 'error',
        condition: 'isDeviceDisconnected',
      },
      extensionDetached: {
        type: 'warning',
        condition: '!isCorrectExtensionAttached',
        resolveState: 'attach-extension',
      },
    },
    stepsNumber: 7,
    states: {
      ...setParametersFlow(null, 'meissa-basing-side-selection', 1),
      ...sideSelectionFlow('basing-settings', 'meissa-basing-set-correct-position', 2),
      ...camMeissaFlow('meissa-basing-side-selection', 'force-calibration', 4),
      ...forceFlow('meissa-basing-range-of-movement', null, 6),
    },
  },
  'cpm-ems-emg/meissa': {
    initial: 'basing-settings',
    alerts: {
      cableDetached: {
        type: 'warning',
        condition: 'isCableDetached',
        resolveState: 'connect-cable',
      },
      extensionDetached: {
        type: 'warning',
        condition: '!isCorrectExtensionAttached',
        resolveState: 'attach-extension',
      },
      disconnectedElectrode: {
        type: 'warning',
        condition: 'areElectrodesDisconnected',
        resolveState: 'connect-electrodes',
      },
      deviceDisconnected: {
        type: 'error',
        condition: 'isDeviceDisconnected',
      },
    },
    stepsNumber: 12,
    states: {
      ...setParametersFlow(null, 'meissa-basing-side-selection', 1),
      ...sideSelectionFlow('basing-settings', 'meissa-basing-set-correct-position', 2),
      ...cpmMeissaFlow('meissa-basing-side-selection', 'connect-cable', 4),
      ...connectCableFlow('meissa-spasticism-armrest', 'channel-muscle-selector', 8),
      ...prepareChannelsAndConnectElectrodesFlow('connect-cable', 'emg-calibration', 9),
      ...emgFlow('connect-electrodes', 'ems-warnings', 12),
      ...emsFlow('emg-calibration', null, 13),
    },
  },
} satisfies CalibrationFlowDefinition;
