import { useEffect } from 'react';

import { useAppNavigate } from './useAppNavigate';

export function navigateAfterLogout(path: string) {
  sessionStorage.setItem('navigate-after-logout', path);
}

export function useNavigateAfterLogout() {
  const navigate = useAppNavigate();

  useEffect(() => {
    const path = sessionStorage.getItem('navigate-after-logout');
    if (path) {
      sessionStorage.removeItem('navigate-after-logout');
      navigate(path);
    }
  }, [navigate]);
}
