import { memo } from 'react';
import { CircularProgress, CircularProgressLabel, useBreakpointValue, useTheme } from '@chakra-ui/react';
import { useSignals } from '@preact/signals-react/runtime';
import { ReadonlySignal } from 'helpers/signal';
import { ChakraSize } from 'types/chakra';

interface CircularProgressWithLabelProps {
  label: ReadonlySignal<string>;
  progress: ReadonlySignal<number>;
  onClick?: () => void;
  size: ChakraSize;
  showLabel?: boolean;
}

const MemoizedCircularProgressLabel = memo(CircularProgressLabel);

// If you have a problem with progress animation, keep in mind that the spinner works correctly only with integer values.

export const CircularProgressWithLabel = ({
  label,
  showLabel = true,
  progress,
  onClick,
  size,
}: CircularProgressWithLabelProps) => {
  useSignals();

  const theme = useTheme();
  const currentSize = useBreakpointValue(typeof size === 'string' ? [size] : size);
  const directSize = currentSize !== undefined ? theme.space[currentSize] : '16';

  return (
    <CircularProgress
      value={progress.value}
      onClick={onClick}
      size={directSize}
      color="egzotechPrimaryColor"
      css={{
        '.chakra-progress__indicator': {
          transition: 'all 0.05s linear',
        },
      }}
      transform="scaleX(-1)"
      data-testid="unweight-progress-element"
    >
      <MemoizedCircularProgressLabel
        color="egzotechPrimaryColor"
        fontWeight="bold"
        transform="translate(-50%, -50%) scaleX(-1)"
      >
        {showLabel && label}
      </MemoizedCircularProgressLabel>
    </CircularProgress>
  );
};
