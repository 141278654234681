import { Fragment } from 'react';
import { Box, Button, HStack } from '@chakra-ui/react';

import { ChannelBadge } from 'components/common/ChannelBadge';
import { MuscleImage, MuscleImageProps } from 'components/common/MuscleImage';
import { TriggerIcon } from 'components/icons';
import { TranslateText } from 'components/texts/TranslateText';

export const ChannelMuscleImage = (
  props: MuscleImageProps & {
    channels: (number | undefined)[];
    hasBorder?: boolean;
    hasTrigger?: boolean;
    maxChannels?: number;
    canAddMore?: boolean;
    onAddMore?: () => void;
  },
) => {
  const { channels, maxChannels, onAddMore, canAddMore, ...rest } = props;

  return (
    <Box boxSize={props.boxSize} position="relative" boxSizing="border-box">
      <Box position="absolute" borderRadius="rLg" overflow="hidden" boxShadow="smDark">
        <MuscleImage {...rest} channels={channels} />
      </Box>
      {props.hasTrigger && (
        <HStack w="full" position="absolute" bottom="4" justifyContent="center">
          <HStack bgColor="egzotechPrimaryColor" borderRadius="xl" px="3" py="0.5" color="white">
            <TriggerIcon stroke="white" boxSize="5" />
            <TranslateText as="span" variant="openSans20" text="calibrationFlow.labels.trigger"></TranslateText>
          </HStack>
        </HStack>
      )}
      {channels?.map((channel, i) => {
        return (
          <Fragment key={channel}>
            <Box
              position="absolute"
              top="50%"
              left="50%"
              w="full"
              h="full"
              border="solid"
              transform="translate(-50%, -50%) scale(0.99)"
              borderColor={props.hasBorder && typeof channel === 'number' ? `channel.${channel + 1}` : 'transparent'}
              borderWidth={(channels.length - i).toString()}
              borderRadius="rLg"
              boxSizing="content-box"
              transition="0.25s all"
            />
          </Fragment>
        );
      })}
      <HStack position="absolute" left={0} bottom={0} gap="2" m={2}>
        {channels?.map(channel => {
          return (
            <Fragment key={channel}>
              {typeof channel === 'number' ? <ChannelBadge size={'xl'} channel={channel} /> : null}
            </Fragment>
          );
        })}
        {canAddMore && typeof maxChannels === 'number' && channels.length > 0 && channels.length < maxChannels && (
          <Button
            variant="flatIconButton"
            borderColor="egzotechPrimaryColor"
            borderWidth="0.5"
            width="16"
            height="16"
            color="egzotechPrimaryColor"
            backgroundColor="whiteAlpha.700"
            fontSize="4xl"
            fontWeight="bold"
            onClick={e => {
              e.stopPropagation();
              onAddMore?.();
            }}
          >
            +
          </Button>
        )}
      </HStack>
    </Box>
  );
};
