import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, Container, Flex, Select, VStack } from '@chakra-ui/react';
import { useSignals } from '@preact/signals-react/runtime';
import { validEmgScales } from 'config/defaultConfigProps';
import colors from 'config/theme/colors';
import { __ } from 'helpers/i18n';
import { useComputed, useSignal } from 'helpers/signal';
import { EMGRealtimeChartDataSource } from 'libs/chart-datasources/EMGChartDataSource';
import {
  CalibrationFlow,
  EMGExerciseDefinition,
  EMGSegmentRecorder,
  SampleBasedTimer,
} from 'libs/exo-session-manager/core';
import { useBasingSettings, useCalibrationFlowState, useDevice } from 'libs/exo-session-manager/react';
import ExoGlobalContext from 'libs/exo-session-manager/react/contexts/ExoGlobalContext';
import { useContextSelector } from 'use-context-selector';
import { useChannelRoleSelector } from 'views/+patientId/training/+trainingId/_components/ConnectElectrodes';

import { TranslateText } from 'components/texts/TranslateText';
import { EMG_WINDOW_WIDTH, EMGTimelineChart } from 'components/timeline-charts';

import { CalibrationFlowBackButton } from '../CalibrationFlowBackButton';
import { CalibrationFlowNextButton } from '../CalibrationFlowNextButton';
import { ChannelToRoleMap } from '../ChannelRoleSelector';
import { CircularProgressWithLabel } from '../CircularProgressWithLabel';

/** 3s in ms */
export const RELAXATION_MEASUREMENT_TOTAL_TIME = 3000;
/** 10ms */
const PROGRESS_TICK = 10;

const translationPrefix = 'exercise.calibration.emg.emg-relaxation-measurement';

export const EmgRelaxationMeasurement = memo(({ flow }: { flow: CalibrationFlow }) => {
  useSignals();
  const { definition } = useBasingSettings(flow);
  const channels = useContextSelector(
    ExoGlobalContext,
    state => state.device.selectedDevice?.channelsConnectionQuality,
  );
  const [channelRoleSelectorData] = useChannelRoleSelector(flow, null);
  const { session } = useDevice();
  const channelRoles = channelRoleSelectorData?.channelRoles as ChannelToRoleMap | null;
  const [isProcessing, setProcessing] = useState(false);
  const [userMeasurementDone, setUserMeasurementDone] = useState(false);
  const [mvcData] = useCalibrationFlowState(flow, 'emg-calibration');
  const selectScaleValue = useSignal<string>(mvcData ? 'mvc' : '10', 'EmgRelaxationMeasurement.selectScaleValue');
  const measurementInterval = useRef<ReturnType<typeof setInterval> | null>(null);
  const realtimeChartDataSourceRef = useRef<EMGRealtimeChartDataSource | null>(null);

  const maxMVCValue = useMemo(() => {
    if (!mvcData || !mvcData.mvc) {
      return null;
    }
    return Math.max(...Object.values(mvcData.mvc).map(d => Math.ceil(d * 1e6)));
  }, [mvcData]);

  if (!channelRoles) {
    throw new Error('Missing data from channel-role-selector flow state');
  }

  if (!channels) {
    throw new Error('Cannot use emg calibration without channels');
  }

  const filteredChannelsBySelectedMuscles = useMemo(
    () => Object.fromEntries(Object.entries(channels).filter(([key]) => key in channelRoles)),
    [channelRoles, channels],
  );

  const channelMask = useMemo(
    () => Object.keys(filteredChannelsBySelectedMuscles).map(ch => +ch),
    [filteredChannelsBySelectedMuscles],
  );

  const resettableEmgTimer = useMemo(() => {
    if (!session) {
      throw new Error('Cannot use calibration without session');
    }

    return new SampleBasedTimer(session);
  }, [session]);
  useEffect(() => {
    if (!session) {
      throw new Error('Cannot use calibration without session');
    }
    realtimeChartDataSourceRef.current = new EMGRealtimeChartDataSource(
      session,
      resettableEmgTimer,
      channelMask,
      EMG_WINDOW_WIDTH,
    );
    return () => {
      if (measurementInterval.current) {
        clearInterval(measurementInterval.current);
      }
      realtimeChartDataSourceRef.current?.dispose();
    };
  }, [channelMask, resettableEmgTimer, session]);

  const emgSegmentRecorder = useMemo(() => {
    if (!session) {
      throw new Error('Cannot use calibration without session');
    }

    return new EMGSegmentRecorder(session, channelMask);
  }, [channelMask, session]);

  const [_, setEmgRelaxationMeasurementData] = useCalibrationFlowState(flow, 'emg-relaxation-measurement');

  const timeToEnd = useSignal(RELAXATION_MEASUREMENT_TOTAL_TIME, 'EmgRelaxationMeasurement.timeToEnd');
  const label = useComputed(
    () => Math.max(Math.ceil(timeToEnd.value / 1000), 0).toString(),
    'EmgRelaxationMeasurement.label',
  );
  const progress = useComputed(
    () => Math.round((timeToEnd.value / RELAXATION_MEASUREMENT_TOTAL_TIME) * 100),
    'EmgRelaxationMeasurement.progress',
  );

  const endMeasurement = useCallback(() => {
    const emgRecording = emgSegmentRecorder.end();
    setEmgRelaxationMeasurementData({ emgRecording });

    emgSegmentRecorder.end();
    timeToEnd.value = RELAXATION_MEASUREMENT_TOTAL_TIME;
    setProcessing(false);
    setUserMeasurementDone(true);
  }, [emgSegmentRecorder, setEmgRelaxationMeasurementData, timeToEnd]);

  const startMeasurement = useCallback(() => {
    setProcessing(true);
    emgSegmentRecorder.reset();
    emgSegmentRecorder.start();

    realtimeChartDataSourceRef.current?.reset();
    realtimeChartDataSourceRef.current?.initializeData();

    resettableEmgTimer.idle();
    resettableEmgTimer.setChannelMask(channelMask);
    resettableEmgTimer.play();

    measurementInterval.current = setInterval(() => {
      timeToEnd.value = timeToEnd.peek() - PROGRESS_TICK;
      if (timeToEnd.peek() <= 0) {
        if (measurementInterval.current) {
          clearInterval(measurementInterval.current);
        }

        endMeasurement();
      }
    }, PROGRESS_TICK);
  }, [channelMask, emgSegmentRecorder, endMeasurement, realtimeChartDataSourceRef, resettableEmgTimer, timeToEnd]);

  const handleOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const scaleValue = event.currentTarget.value;
    selectScaleValue.value = scaleValue;
  };

  useEffect(() => {
    // Always reset EMG calibration when this user is entering this screen, we do not want previous calibration from
    // repeated exercise. This will also reset calibration when user goes back and I think this is correct, because
    // user could change electrodes placement or muscles, so anyway user needs to calibrate EMG again
    setEmgRelaxationMeasurementData({ emgRecording: undefined });
  }, [setEmgRelaxationMeasurementData]);

  return (
    <Container variant="calibrationFlowMainWrapper" data-testid="emg-calibration-container">
      <Container variant="calibrationFlowIlustrationWrapperWithoutShadow">
        <TranslateText
          text={
            isProcessing
              ? `${translationPrefix}.command2`
              : userMeasurementDone
              ? `${translationPrefix}.ready`
              : `${translationPrefix}.command1`
          }
          variant="openSans60Bold"
          color={colors.egzotechPrimaryColor}
          textAlign="center"
        />
        <Select
          defaultValue={selectScaleValue.value}
          value={selectScaleValue.value}
          width={44}
          mt={3.5}
          ml={'auto'}
          mr={{ base: 8, '2xl': 9 }}
          height={12}
          onChange={handleOnChange}
        >
          {maxMVCValue && <option value="mvc">mvc</option>}
          {validEmgScales.map(el => (
            <option key={el} value={el}>
              {`${el} ${__('units.microvolts')}`}
            </option>
          ))}
        </Select>

        <VStack w="full" height="31.5rem">
          {realtimeChartDataSourceRef.current && (
            <EMGTimelineChart
              chartDataSource={realtimeChartDataSourceRef.current}
              yMax={(selectScaleValue.value === 'mvc' ? maxMVCValue : parseInt(selectScaleValue.value)) ?? 10}
              yMaxMvc={10}
              emgExerciseDefinition={definition as EMGExerciseDefinition}
              hideChannels={[]}
              // -35 below is needed for visualization. Without it, the line ends before the frame.
              timeLineOptions={{
                rangeViewData: { min: 0, max: (RELAXATION_MEASUREMENT_TOTAL_TIME - 40) / 1000 },
                splitNumber: RELAXATION_MEASUREMENT_TOTAL_TIME / 1000,
              }}
            />
          )}
        </VStack>
      </Container>
      <Container variant="calibrationFlowDescriptionWrapper">
        <TranslateText text={`${translationPrefix}.heading`} variant="openSans36Bold" letterSpacing="0" mb="10" />

        <Flex direction="column" alignItems="center" my="auto">
          <TranslateText>
            <TranslateText my="auto" variant="openSans24" as="span" text={`${translationPrefix}.description1_1`} />
            <TranslateText
              as="span"
              variant="openSans24Bold"
              text={`${translationPrefix}.description1_2`}
              replace={{ time: RELAXATION_MEASUREMENT_TOTAL_TIME / 1000 }}
            />
          </TranslateText>
          <Flex minH={36} alignItems={'center'} justifyItems="center" mt="12">
            {isProcessing ? (
              <Box>
                <CircularProgressWithLabel label={label} progress={progress} size="36" />
              </Box>
            ) : (
              <Button
                variant={{ base: 'smPrimaryButton', '2xl': 'mdPrimaryButton' }}
                onClick={startMeasurement}
                data-testid="measure-emg-relaxation-button"
                minWidth={56}
              >
                <TranslateText
                  text={
                    userMeasurementDone ? `${translationPrefix}.repeat` : `calibrationFlow.labels.measureEmgRelaxation`
                  }
                ></TranslateText>
              </Button>
            )}
          </Flex>
        </Flex>
        <Flex position="relative" justifyContent="space-between" width={'100%'}>
          <CalibrationFlowBackButton flow={flow} />
          <CalibrationFlowNextButton flow={flow} />
        </Flex>
      </Container>
    </Container>
  );
});

EmgRelaxationMeasurement.displayName = 'EmgRelaxationMeasurement';
