import { DeviceType, SensorsName } from 'libs/exo-session-manager/core';

export const defaultDelay = 300;
export const leftThighMinSize = 30;
export const rightThighMinSize = 49;
export const thighUnit = 'cm';

export const maxDeviceRangeKnee = 120;
export const minDeviceRangeKnee = -10;

export const maxDeviceRangeAnkle = 25;
export const minDeviceRangeAnkle = -45;

export const numberSpeedMeasurements = 3;

// Angle measurement tolerance for devices. Added in the task EGZOTech/exo-clinic#811
export const deviceTolerances: Record<DeviceType, number> = {
  'sidra-leg': 0.5,
  'meissa-ot': 2,
  'stella-bio': 0,
  unspecified: 0,
};

export const rangeTolerances: Partial<Record<DeviceType, { min: number; portion: number }>> = {
  'sidra-leg': { min: 5, portion: 0.25 },
  'meissa-ot': { min: deviceTolerances['meissa-ot'], portion: 0.25 },
};

// FIXME: this probably should be changed
export const sensorsRange = {
  knee: { max: 75, min: -75 },
  toes: { max: 100, min: -100 },
  heel: { max: 100, min: -100 },
  extension: { max: 100, min: 0 },
  torque: { max: 50, min: -50 },
  horizontal: { max: 90, min: -90 }, // meissa horizontal Angle
  vertical: { max: 90, min: 0 }, // meissa vertical Angle
};

export const parameterValidationsConfig = [
  {
    parameter: 'startActiveMovementForce',
    dependsOn: 'endActiveMovementForce',
    validationNameFn: 'lessThan',
  },
  {
    parameter: 'endActiveMovementForce',
    dependsOn: 'startActiveMovementForce',
    validationNameFn: 'greaterThan',
  },
  {
    parameter: 'activeMovementDirection',
    dependsOn: 'forceSource',
    validationNameFn: 'excludeBothMovementDirection',
  },
];

export const currentVersionOfAdditionalData = 1;

export const defaultSensorNameByDevice: Partial<Record<DeviceType, SensorsName>> = {
  'sidra-leg': 'toes',
  'meissa-ot': 'torque',
};

export const unavailableTrainingTypesByDevice: Partial<Record<DeviceType, string[]>> = {
  'sidra-leg': ['cpm_force_classic', 'emg', 'ems-emg', 'ems', 'emg_game'],
  'meissa-ot': ['emg', 'ems-emg', 'ems', 'emg_game'],
  'stella-bio': [
    'cpm_classic',
    'cpm_emg_classic',
    'cpm_ems_classic',
    'cpm_ems_emg_classic',
    'cam_isokinetic_classic',
    'cam_torque_classic',
    'cpm_force_classic',
    'cam_game',
  ],
};

// Array of parameter IDs that can be multi-line
export const multiLineParametersIdArray = ['normal', 'reverse', 'toMin', 'toMax'];

// Minimum length of parameter text when it stays multiline
export const multiLineParametersMinLength = 18;

export const validEmgScales = [1, 2, 5, 10, 20, 50, 100, 200, 500, 1000, 2000, 5000] as const;
export type ValidEMGScales = keyof (typeof validEmgScales)[][number];

// Global wifi status interval
export const globalWifiStatusInterval = 30000;
// Login screen extra wifi status interval for 30s.
export const loginScreenWifiStatusInterval = 3000;
// We can force a scan once every 61 seconds.
export const tempForceScanWifiTimeBlock = 30000;
// Wi-Fi status timeout on the login screen. After logging out, we lose the Wi-Fi connection, so on the login screen,we need to check its status frequently.
export const loginScreenWifiStatusTimeout = 30000;
