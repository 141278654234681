import { memo, ReactNode, useCallback } from 'react';
import { Box, Button, VStack } from '@chakra-ui/react';
import { useSignals } from '@preact/signals-react/runtime';
import { maxValueFormatterHelper } from 'helpers/maxValueFormatter';
import { ReadonlySignal, useComputed, useSignal, useSignalEffect } from 'helpers/signal';

import { EMGBar, EMGBarTemplate } from 'components/common/EMGBar';
import { TranslateText } from 'components/texts/TranslateText';

import { BasingSignalBarTextInfo } from './BasingSignalBarTextInfo';

interface BasingSignalBarProps {
  active: boolean;
  isThresholdDisabled?: boolean;
  value: ReadonlySignal<number>;
  onReset?: () => void;
  onChange: (name: string, maxValue: number, threshold: number) => void;
  topLabel: ReactNode;
  unit: string;
  name: string;
  color: string;
  lightColor: string;
  maxValueFormatter?: (maxValue: number) => string;
  maxValueTitle?: string;
  resetTitle?: string;
  isNegative?: boolean;
  emgBarTemplate?: EMGBarTemplate;
}

export const BasingSignalBar = memo(
  ({
    active = false,
    isThresholdDisabled = false,
    value,
    onReset,
    onChange,
    topLabel,
    unit,
    name,
    color,
    lightColor,
    maxValueFormatter = maxValueFormatterHelper,
    maxValueTitle = 'exercise.calibration.maxValue',
    resetTitle = 'common.reset',
    isNegative = false,
    emgBarTemplate: template,
  }: BasingSignalBarProps) => {
    useSignals();
    const maxValue = useSignal(0, 'BasingSingalBar.maxValue');
    const thresholdLevel = useSignal(50, 'BasingSingalBar.thresholdLevel');

    const onLevelChange = useCallback(
      (level: number) => {
        thresholdLevel.value = level;
      },
      [thresholdLevel],
    );

    const onMaxValueReset = useCallback(() => {
      onReset?.();
      maxValue.value = 0;
    }, [maxValue, onReset]);

    useSignalEffect(() => {
      const mvcValue = maxValue.value;
      const thresholdValue = thresholdLevel.value;

      if (!active) {
        return;
      }

      const ref = setTimeout(() => {
        onChange(name, mvcValue, thresholdValue);
      }, 250);

      return () => {
        clearTimeout(ref);
      };
    });

    useSignalEffect(() => {
      if (isNegative && value.value < 0 && Math.abs(value.value) > maxValue.value) {
        maxValue.value = Math.abs(value.value);
      } else if (!isNegative && value.value > 0 && value.value > maxValue.value) {
        maxValue.value = value.value;
      }
    });

    const mvcStr = useComputed(() => maxValueFormatter(maxValue.value), 'BasingSignalBar.mvcStr');
    const currentValue = useComputed(
      () => (isNegative ? (value.value < 0 ? value.value : 0) : value.value > 0 ? value.value : 0),
      'BasingSignalBar.currentValue',
    );
    return (
      <VStack gap={{ base: '3', '2xl': '5' }}>
        <Box>{topLabel}</Box>
        <VStack gap={{ base: '0', xl: 6 }}>
          <EMGBar
            channel={0}
            label={name}
            color={color}
            lightColor={lightColor}
            currentValue={currentValue}
            maxValue={maxValue}
            onLevelChange={onLevelChange}
            thresholdLevel={thresholdLevel.value}
            isDisabled={!active}
            isThresholdDisabled={isThresholdDisabled}
            emgBarTemplate={template}
          />
          <BasingSignalBarTextInfo
            color={color}
            active={active}
            label={maxValueTitle}
            thresholdLevel={thresholdLevel.value}
            isThresholdDisabled={isThresholdDisabled}
          >
            {mvcStr} <TranslateText as="span" text={unit} />
          </BasingSignalBarTextInfo>
        </VStack>

        <Button
          variant={'mdOutlinedPrimaryButton'}
          cursor={active ? 'pointer' : 'not-allowed'}
          color={active ? color : 'gray.300'}
          borderColor={active ? color : 'gray.300'}
          maxWidth={20}
          py={8}
          onClick={onMaxValueReset}
        >
          <TranslateText text={resetTitle} whiteSpace="pre-wrap" />
        </Button>
      </VStack>
    );
  },
);

BasingSignalBar.displayName = 'BasingSignalBar';
