import { Language } from 'types/language';

const AppConfig: {
  LANGUAGES: Language[];
  DEFAULT_LANG: Language;
  DATE_FORMAT: string;
  TIME_FORMAT: string;
  DAY_FORMAT: string;
  PRESENTATION_DATE_FORMAT: string;
  PRESENTATION_DATE_FORMAT_DOT: string;
  LONG_DAY_FORMAT: string;
  LONG_DAY_YEARS_FORMAT: string;
  LONG_DATE_FORMAT: string;
  MONTH_FORMAT: string;
  TIME_AND_DATE_FORMAT: string;
  DATE_FORMAT_AND_TIME: string;
} = {
  LANGUAGES: ['pl', 'en', 'de', 'zh_Hans', 'zh_Hant'],
  DEFAULT_LANG: 'pl',
  DATE_FORMAT: 'yyyy-MM-dd',
  TIME_FORMAT: 'HH:mm',
  DAY_FORMAT: 'dd/MM',
  PRESENTATION_DATE_FORMAT: 'dd/MM/yyyy',
  PRESENTATION_DATE_FORMAT_DOT: 'dd.MM.yyyy',
  LONG_DAY_FORMAT: 'd MMMMMM',
  LONG_DAY_YEARS_FORMAT: 'd MMMMMM yyyy',
  LONG_DATE_FORMAT: 'iiii, d MMMMMM',
  MONTH_FORMAT: 'LLLL yyyy',
  TIME_AND_DATE_FORMAT: 'HH:mm dd.MM.yyyy',
  DATE_FORMAT_AND_TIME: 'dd.MM.yyyy HH:mm',
};

export default AppConfig;
