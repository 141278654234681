import { useEffect, useRef } from 'react';
import { store } from 'config/store';
import { errorNotification } from 'helpers/handleNotifications';
import { EGZOTechHostApi } from 'services/EGZOTechHostApi';
import { exoClinicApiWithoutError } from 'services/ExoClinicBackendApi';
import { setWifiDisconnect, setWifiStatus } from 'slices/wifiSlice';

export const useWifiStatusInterval = (interval: number) => {
  const intervalId = useRef<NodeJS.Timeout | null>(null);
  const checkWifiStatus = async () => {
    try {
      await exoClinicApiWithoutError.device.wifi.status().then(res => {
        if (res.connected) {
          store.dispatch(setWifiStatus(res));
        } else {
          store.dispatch(setWifiDisconnect());
        }
      });
    } catch (err) {
      store.dispatch(setWifiDisconnect);
      console.log(err);

      if (!EGZOTechHostApi.instance?.options?.disableLostWifiNotification) {
        errorNotification('wifi.notifications.lostWifiConnection');
      }
    }
  };

  useEffect(() => {
    intervalId.current = setInterval(() => {
      const isBackendReady = store.getState().update.software.currentVersion;

      if (isBackendReady) {
        checkWifiStatus();
      }
    }, interval);

    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
    };
  }, [interval]);

  const stopInterval = () => {
    if (intervalId.current) {
      clearInterval(intervalId.current);
    }
  };

  return { stopInterval };
};
