import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useBreakpointValue } from '@chakra-ui/react';
import { useSignalEffect, useSignals } from '@preact/signals-react/runtime';
import colors, { Channel } from 'config/theme/colors';
import * as echarts from 'echarts';
import { MarkLine2DDataItemOption } from 'echarts/types/src/component/marker/MarkLineModel';
import { Signal } from 'helpers/signal';
import { ChannelMap, TimeLinePoint } from 'libs/chart-datasources';
import { EMGExerciseDefinition } from 'libs/exo-session-manager/core';

const _getXPixelWidth = (echartsRef: echarts.ECharts) => {
  const valueRange = 10;
  const xMinPixel = echartsRef.convertToPixel({ xAxisIndex: 0 }, 0);
  const xMaxPixel = echartsRef.convertToPixel({ xAxisIndex: 0 }, valueRange);
  return valueRange / (xMaxPixel - xMinPixel);
};

const getYPixelWidth = (echartsRef: echarts.ECharts) => {
  const valueRange = 100;
  const yMinPixel = echartsRef.convertToPixel({ yAxisIndex: 0 }, 0);
  const yMaxPixel = echartsRef.convertToPixel({ yAxisIndex: 0 }, valueRange);
  return valueRange / (yMinPixel - yMaxPixel);
};

function calculateThresholdTimeChanges(array: EMGArrayElement[]) {
  if (array.length < 1) {
    return [];
  }
  const result: { start: number; end: number; threshold: number; withRest: boolean }[] = [];
  let start = 0;
  let currentThreshold = array[0].threshold;

  for (let i = 0; i < array.length; i++) {
    const element = array[i];
    const endLast = element.time + element.width + element.rest;
    if (element.threshold !== currentThreshold) {
      const end = array[i - 1].time + array[i - 1].width + array[i - 1].rest;
      result.push({ start, end, threshold: currentThreshold, withRest: array[i - 1].rest > 0 });
      currentThreshold = element.threshold;
      start = end;
    }
    if (i === array.length - 1) {
      result.push({ start, end: endLast, threshold: currentThreshold, withRest: element.rest > 0 });
    }
  }

  return result;
}

type EMGArrayElement = {
  time: number;
  threshold: number;
  width: number;
  rest: number;
};

export const analyzeEMGProgram = (emgProgram: EMGExerciseDefinition | null) => {
  const emgArray: EMGArrayElement[] = [];
  let timeOffset = 0;

  if (emgProgram) {
    for (const step of emgProgram.emg.program.steps) {
      timeOffset += step.initialRelaxation;
      for (let repetition = 0; repetition < step.repetitions; repetition++) {
        emgArray.push({
          time: timeOffset,
          threshold: step.threshold,
          width: step.workTime,
          rest: step.restTime,
        });
        timeOffset += step.workTime + step.restTime;
      }
    }
  }

  return {
    totalTime: timeOffset,
    emgArray,
  };
};

export type TimeLineChartOptions = {
  shiftDatasetsOnAxisY?: boolean;
  showTimeMark?: boolean;
  windowWidth?: number;
  yAxisUnit?: string;
  yAxisMax?: number;
  yAxisMvc?: number;
  emgExerciseDefinition?: EMGExerciseDefinition;
  hideChannels?: number[];
  exerciseName?: string;
  rangeViewData?: { min: number; max: number };
  splitNumber?: number;
};

const getYThreshold = (yAxisMvc: number, threshold: number) => {
  return (threshold / 100) * (yAxisMvc * 1000 * 1000);
};

export const MARK_LINE_WIDTH = 2;
export const DEFAULT_WINDOW_WIDTH = 20;

export const useRealtimeTimelineChart = (
  timelines: ChannelMap<TimeLinePoint[]>,
  time: Signal,
  options: TimeLineChartOptions,
  selectedSegment?: Signal<number | undefined>,
) => {
  useSignals();
  const chartRef = useRef<HTMLDivElement>(null);
  const echartInstance = useRef<echarts.ECharts | null>(null);
  const echartOptions = useRef<echarts.EChartsOption | null>(null);
  const fontSize = useBreakpointValue({
    base: 16,
    '2xl': 20,
  });

  const {
    shiftDatasetsOnAxisY = false,
    showTimeMark = true,
    windowWidth: windowWidth = DEFAULT_WINDOW_WIDTH,
    emgExerciseDefinition = null,
    yAxisUnit = '%',
    yAxisMax: yMax = 100,
    yAxisMvc = 0,
    hideChannels = [],
    rangeViewData,
    splitNumber = windowWidth,
  } = options;

  const convertDataset = useCallback((dataset: TimeLinePoint[], shift: number) => {
    if (!echartInstance.current || !echartOptions.current) {
      return;
    }

    if (!shift) {
      return dataset;
    }
    const yShift = shift * getYPixelWidth(echartInstance.current) * 5; // 5 is based mockups
    return dataset.map((v, i) => {
      const prevPoint = dataset[i - 1];
      const nextPoint = dataset[i + 1];

      let deltaX = 0;
      let deltaY = 0;

      let calculatedXShift = 0;
      let calculatedYShift = 0;
      if (!v[1]) {
        if (prevPoint && prevPoint[1]) {
          deltaX = v[0] - prevPoint[0];
          deltaY = prevPoint[1] - v[1];
        } else if (nextPoint && nextPoint[1]) {
          deltaX = v[0] - nextPoint[0];
          deltaY = nextPoint[1] - v[1];
        }
      } else {
        calculatedYShift = yShift;
      }

      if (deltaY) {
        calculatedXShift = (deltaX / deltaY) * yShift;
      }

      return [v[0] - calculatedXShift, v[1] - calculatedYShift];
    });
  }, []);

  const { emgArray } = useMemo(() => analyzeEMGProgram(emgExerciseDefinition), [emgExerciseDefinition]);
  const thresholdTimeChanges = useMemo(() => calculateThresholdTimeChanges(emgArray), [emgArray]);

  const getMarkLineLevel = useCallback(
    (threshold: number) => {
      let markLineLevel = getYThreshold(yAxisMvc, threshold);
      if (markLineLevel > yMax) {
        markLineLevel = yMax;
      }
      return markLineLevel;
    },
    [yAxisMvc, yMax],
  );

  const getEMGMarkingForSeries = useCallback(() => {
    echartInstance.current?.setOption({});

    const markAreas = emgArray.map(
      v =>
        [
          {
            xAxis: v.time,
            yAxis: 0,
          },
          {
            xAxis: v.time + v.width,
            yAxis: getMarkLineLevel(v.threshold),
          },
        ] as [{ xAxis: number; yAxis: number }, { xAxis: number; yAxis: number }],
    );

    const guidelines = emgArray.flatMap(v => {
      const markLineLevel = getMarkLineLevel(v.threshold);

      let upperArrow = 'arrow';
      let bottomArrow = 'arrow';

      if (markLineLevel > yMax) {
        upperArrow = 'none';
      } else if (markLineLevel < yMax * 0.1) {
        // hide arrows if the the work area is below 0.1 of maximum y
        upperArrow = 'none';
        bottomArrow = 'none';
      }

      return [
        [
          {
            name: 'arrowheadUp',
            coord: [v.time, 0],
            symbol: 'none',
            itemStyle: {
              color: colors.egzotechPrimaryColor,
            },
            lineStyle: {
              color: colors.egzotechPrimaryColor,
              type: 'solid',
              width: 3,
            },
          },
          {
            name: 'arrowheadUp2',
            coord: [v.time, markLineLevel],
            symbol: upperArrow,
            itemStyle: {
              color: colors.egzotechPrimaryColor,
            },
            lineStyle: {
              color: colors.egzotechPrimaryColor,
              type: 'solid',
              width: 3,
            },
          },
        ] satisfies MarkLine2DDataItemOption,
        [
          {
            name: 'arrowUp',
            coord: [v.time, 0],
            symbol: 'none',
            itemStyle: {
              color: colors.egzotechPrimaryColor,
            },
            lineStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 1,
                x2: 0,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: 'white',
                  },
                  {
                    offset: 1,
                    color: colors.egzotechPrimaryColor,
                  },
                ],
              },
              type: 'solid',
              width: 3,
            },
          },
          {
            name: 'arrowUp2',
            coord: [v.time, markLineLevel],
            symbol: 'none',
            itemStyle: {
              color: colors.egzotechPrimaryColor,
            },
            lineStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 1,
                x2: 0,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: 'white',
                  },
                  {
                    offset: 1,
                    color: colors.egzotechPrimaryColor,
                  },
                ],
              },
              type: 'solid',
              width: 3,
            },
          },
        ] satisfies MarkLine2DDataItemOption,
        [
          {
            name: 'arrowheadDown',
            coord: [v.time + v.width, markLineLevel],
            symbol: 'none',
            itemStyle: {
              color: colors.arrowDownColor,
            },
            lineStyle: {
              color: colors.arrowDownColor,
              type: 'solid',
              width: 3,
            },
          },
          {
            name: 'arrowheadDown2',
            coord: [v.time + v.width, 0],
            symbol: bottomArrow,
            itemStyle: {
              color: colors.arrowDownColor,
            },
            lineStyle: {
              color: colors.arrowDownColor,
              type: 'solid',
              width: 3,
            },
          },
        ] satisfies MarkLine2DDataItemOption,
        [
          {
            name: 'arrowDown',
            coord: [v.time + v.width, markLineLevel],
            symbol: 'none',
            itemStyle: {
              color: colors.arrowDownColor,
            },
            lineStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'white',
                  },
                  {
                    offset: 1,
                    color: colors.arrowDownColor,
                  },
                ],
              },
              type: 'solid',
              width: 3,
            },
          },
          {
            name: 'arrowDown2',
            coord: [v.time + v.width, 0],
            symbol: 'none',
            itemStyle: {
              color: colors.arrowDownColor,
            },
            lineStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'white',
                  },
                  {
                    offset: 1,
                    color: colors.arrowDownColor,
                  },
                ],
              },
              type: 'solid',
              width: 3,
            },
          },
        ] satisfies MarkLine2DDataItemOption,
      ];
    });

    const singleThreshold =
      thresholdTimeChanges.length === 1
        ? [
            {
              name: 'thresholdLine',
              yAxis: getMarkLineLevel(thresholdTimeChanges[0].threshold),
              lineStyle: {
                color: colors.egzotechPrimaryColor,
                type: 'dashed',
                width: 1,
              },
            } satisfies MarkLine2DDataItemOption[0],
          ]
        : [];

    const series: echarts.EChartsOption['series'] = [
      {
        name: 'guidelines',
        type: 'line' as const,
        data: [],
        z: 4,
        markLine: {
          symbolSize: [12, 20],
          symbol: ['none', 'none'],
          z: 4,
          label: {
            show: false,
          },
          data: [...guidelines, ...singleThreshold],
        },
        markArea: {
          z: 4,
          itemStyle: {
            color: `rgba(196, 236, 255, 0.5)`,
          },
          data: markAreas,
        },
      },
    ];

    return series;
  }, [emgArray, getMarkLineLevel, thresholdTimeChanges, yMax]);

  const getDataSeries = useCallback(
    (timelines: ChannelMap<TimeLinePoint[]>, hideChannels: number[]) => {
      if (!echartInstance.current || !echartOptions.current) {
        return [];
      }

      let shift = 0;
      const series = timelines
        .entries()
        .filter(v => !hideChannels.includes(v[0]))
        .map(([channelIndex, values]) => {
          const data = convertDataset(values, shiftDatasetsOnAxisY ? shift : 0);
          if (data && data.length) {
            shift++;
          }
          return {
            name: `line${channelIndex}`,
            type: 'line' as const,
            showSymbol: false,
            data,
            animation: false,
            triggerLineEvent: true,
            showAllSymbol: false,
            legendHoverLink: false,
            sampling: 'none',
            progressive: false,
            smooth: false,
            color: colors.channel[(channelIndex + 1) as Channel],
            z: 5,
          } as echarts.SeriesOption;
        });

      return series;
    },
    [convertDataset, shiftDatasetsOnAxisY],
  );

  const currentMin = (echartOptions.current?.xAxis as echarts.XAXisComponentOption | undefined)?.min as number;
  const timeResetted = time.value < 1 && currentMin > 0;

  const initializeChart = useCallback(() => {
    if (!echartInstance.current) {
      return;
    }
    const rangeData = rangeViewData
      ? rangeViewData
      : time.value > windowWidth / 2
      ? { min: time.value - windowWidth / 2, max: time.value + windowWidth / 2 }
      : { min: 0, max: windowWidth };

    echartOptions.current = {
      [Symbol.toStringTag]: 'Function',
      xAxis: {
        type: 'value',
        name: '[s]',
        nameGap: 30,
        nameLocation: 'middle',
        nameTextStyle: {
          fontSize,
          fontWeight: 'bold',
          color: colors.gray[400],
        },
        ...rangeData,
        splitNumber: splitNumber,
        animation: false,
        splitLine: {
          show: true,
          lineStyle: {
            color: colors.chartAxisColor,
          },
        },
        axisLabel: {
          interval: 0,
          animation: false,
          formatter: (_time: number) => {
            const time = Math.floor(_time);
            const seconds = time % 60;
            const minutes = (time - seconds) / 60;
            return `${minutes}:${seconds.toString().padStart(2, '0')}`;
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: colors.chartAxisColor,
            },
          },
          showMinLabel: false,
          showMaxLabel: false,
          fontSize,
          color: colors.gray[400],
        },
      },
      yAxis: {
        type: 'value',
        nameGap: 30,
        nameLocation: 'middle',
        nameTextStyle: {
          fontSize,
          fontWeight: 'bold',
          color: colors.gray[400],
        },
        axisLabel: {
          color: colors.gray[400],
          fontSize,
          formatter: (value: number) => {
            return `${value}` + (yAxisUnit ? ` ${yAxisUnit}` : '');
          },
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: colors.chartAxisColor,
          },
        },
        animation: false,
        min: 0,
        max: yMax,
        splitLine: {
          show: true,
          lineStyle: {
            color: colors.chartAxisColor,
          },
        },
      },

      grid: {
        left: '5%',
        right: '5%',
        top: '5%',
        bottom: '10%',
        containLabel: true,
      },
      animation: false,
      tooltip: { show: false },
      toolbox: { show: false },
      title: { show: false },
    } as echarts.EChartsOption;
    echartInstance.current.setOption<echarts.EChartsOption>(echartOptions.current);
    // Initialize only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fontSize, windowWidth, yAxisUnit, yMax, time]);

  const getThresholdSeries = useCallback(
    (timeWindowStart: number, timeWindowEnd: number) => {
      if (thresholdTimeChanges.length < 2) {
        return [];
      }

      const visibleThresholds = thresholdTimeChanges.filter(
        ({ start, end }) => end >= timeWindowStart && start <= timeWindowEnd,
      );

      const data = visibleThresholds.flatMap(({ start, end, threshold }) => [
        [Math.max(start, timeWindowStart), getMarkLineLevel(threshold)],
        [Math.min(end, timeWindowEnd), getMarkLineLevel(threshold)],
      ]);

      const sectionLines = visibleThresholds.reduce((prev, { end, threshold, withRest }) => {
        if (timeWindowEnd > end) {
          prev?.push([
            {
              name: 'sectionLine1',
              coord: [end, withRest ? 0 : getMarkLineLevel(threshold)],
              lineStyle: {
                color: colors.egzotechPrimaryColor,
                type: 'solid',
                width: MARK_LINE_WIDTH,
              },
            },
            {
              name: 'sectionLine2',
              coord: [end, yMax],
              lineStyle: {
                color: colors.egzotechPrimaryColor,
                type: 'solid',
                width: MARK_LINE_WIDTH,
              },
            },
          ] satisfies MarkLine2DDataItemOption);
        }
        return prev;
      }, [] as echarts.MarkLineComponentOption['data']);

      const markLine =
        sectionLines && sectionLines.length > 0
          ? ({
              z: 2,
              symbol: 'none',
              silent: true,
              label: {
                show: false,
              },
              data: sectionLines,
            } satisfies echarts.MarkLineComponentOption)
          : undefined;

      const series = [
        {
          name: 'threshold',
          type: 'line' as const,
          showSymbol: false,
          data,
          animation: false,
          showAllSymbol: false,
          legendHoverLink: false,
          sampling: 'none',
          progressive: false,
          smooth: false,
          lineStyle: {
            color: colors.egzotechPrimaryColor,
            type: 'dashed',
            width: 1,
          },
          markLine,
          z: 2,
        } satisfies echarts.SeriesOption,
      ];
      return series;
    },
    [getMarkLineLevel, thresholdTimeChanges, yMax],
  );

  const updateChartData = useCallback(() => {
    if (!echartOptions.current) {
      return;
    }
    const emgSeries = getEMGMarkingForSeries();
    const baseSeries = getDataSeries(timelines, hideChannels);
    const thresholdSeries = getThresholdSeries(0, windowWidth);
    echartOptions.current.series = [...emgSeries, ...baseSeries, ...thresholdSeries];
    echartInstance.current?.setOption<echarts.EChartsOption>(echartOptions.current);
  }, [getEMGMarkingForSeries, getDataSeries, timelines, hideChannels, getThresholdSeries, windowWidth]);

  const getTimeSeries = useCallback(
    (time: number) => {
      if (!echartInstance.current) {
        return;
      }

      const series = [
        {
          name: 'timemark',
          type: 'line' as const,
          data: [
            [time, 0],
            [time, yMax],
          ],
          symbol: 'none',
          silent: true,
          label: {
            show: false,
          },
          z: 6,
          lineStyle: {
            color: colors.egzotechPrimaryColor,
            type: 'solid',
            width: MARK_LINE_WIDTH,
          },
        },
      ] satisfies echarts.EChartsOption['series'];

      return series;
    },
    [yMax],
  );

  const updateTimeMarks = useCallback(
    (time: number) => {
      const timeDelta = time - windowWidth / 2;
      if (!echartInstance.current) {
        return;
      }

      const timeWindowStart = timeDelta > 0 ? timeDelta : 0;
      const timeWindowEnd = timeWindowStart + windowWidth;

      if (echartOptions.current?.xAxis && !Array.isArray(echartOptions.current.xAxis) && timeWindowStart > 0) {
        echartOptions.current.xAxis.min = timeWindowStart;
        echartOptions.current.xAxis.max = timeWindowEnd;
      }
      const baseSeries = echartOptions.current?.series as echarts.EChartsOption['series'][];
      const timeSeries = showTimeMark ? getTimeSeries(time) ?? [] : [];
      const thresholdSeries = getThresholdSeries(timeWindowStart, timeWindowEnd);
      if (thresholdSeries.length > 0) {
        const thresholdPrevIndex = baseSeries.findIndex(s => !Array.isArray(s) && s?.name === 'threshold');
        if (thresholdPrevIndex !== -1) {
          baseSeries.splice(thresholdPrevIndex);
        }
      }

      echartInstance.current?.setOption({
        series: [...thresholdSeries, ...baseSeries, ...timeSeries],
      });
    },
    [getThresholdSeries, getTimeSeries, showTimeMark, windowWidth],
  );

  const setInitialRange = useCallback(() => {
    if (!selectedSegment || selectedSegment.value === undefined) {
      return;
    }
    if (!echartOptions.current || !Array.isArray(echartOptions.current.series)) {
      return;
    }

    const firstLineData = echartOptions.current.series.find(s => /^line\d+$/.test(s.name as string));
    const maxTime = (firstLineData?.data as [number, number][] | undefined)?.at(-1)?.[0];
    if (echartOptions.current?.xAxis && !Array.isArray(echartOptions.current.xAxis)) {
      echartOptions.current.xAxis.max = Math.max(maxTime ?? windowWidth, windowWidth);
      echartOptions.current.xAxis.min = 0;
      echartInstance.current?.setOption(echartOptions.current);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSegment?.value, windowWidth]);

  useEffect(() => {
    const resizeHandler = () => {
      echartInstance.current?.resize();
      updateChartData();
      updateTimeMarks(time.value);
    };

    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
    // add event listener only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!chartRef.current) return;
    echartInstance.current = echarts.init(chartRef.current, null, {
      renderer: 'canvas',
      devicePixelRatio: 1,
      useDirtyRect: true,
    });

    initializeChart();
    updateChartData();
    updateTimeMarks(time.value);
    setInitialRange();

    return () => {
      echartInstance.current?.dispose();
    };
    // do not subscribe to update callbacks but execute them when chart instance is changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initializeChart, timelines, timeResetted, hideChannels]);

  useSignalEffect(() => {
    updateTimeMarks(time.value);
  });

  return { chartRef, echartInstance };
};
